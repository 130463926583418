<template>
  <div class="bottom">
    <!-- <div class="bottom_A">
      <div class="A_column">
        <div v-for="(b1, index) in bottomArr" :key="index" class="titleBox">
          {{ b1.title }}
          <ul class="content">
            <li v-for="(b2, index) in b1.other" :key="index">
              <router-link :to="b2.id ? {path:b2.path,query:{id:b2.id}} : b2.path" style="color: aliceblue">{{
                b2.name
              }}</router-link>
            </li>
          </ul>
          <ul class="more" v-show="b1.title === '问皓产品'">
            <li>
              <router-link to="/products" style="color: aliceblue">
                More</router-link
              >
            </li>
            <li>
              <router-link to="/products" style="color: aliceblue">
                More</router-link
              >
            </li>
          </ul>
        </div>
        <img src="../assets/logo.png" alt="" />
      </div>
    </div> -->
    <div class="bottom_B">
      <ul class="state">
        <li v-for="(s, index) in state" :key="index">
          <a :href="s.href" style="color: aliceblue">{{ s.name }}</a>
        </li>
      </ul>
     <div class="baseinfo">
        <!-- <img src="../assets/logo.png" /> -->
        <div class="info" v-for="(info, index) in baseInfo" :key="index">
          {{ info }}
        </div>
      </div>
	  
	  <div class="remove_data">
	  	  <div>
	  		  <div class="link"  @click="toDeleteData()">Data Delete</div>
	  	  </div>
	  </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  data() {
    return {
      bottomArr: [
        {
          title: "About us",
          other: [
            {
              name: "Infomation",
              path: "/about",
            },
            {
              name: "Overview",
              path: "/about",
            },
          ],
        },
        {
          title: "Business",
          other: [
            {
              name: "Contact us",
              path: "/about",
              id:'contact'
            },
          ],
        },
      ],
      state: [
        // {name:"Statement",href:"#"},
        // {name:"Customer Service",href:"#"},
        // {name:"Terms of Service",href:"https://sparklingfishstudio.com/termsofservice"},
        // {name:"Privacy Policy",href:"https://sparklingfishstudio.com/privacy"}
      ],
      baseInfo: [
      //   "官网地址: wenhaogame.com",
      //   "联系电话: 18620191898",
      //   "公司地址: 广州市天河区华强路3号之二2105室",
      //   "备案号: 粤ICP备2022024892号-1",
			"Company: Guangzhou Galactic Spark Technology Ltd."
      ],
    };
  },
  methods:{
	  toDeleteData(){
		this.$router.push({path:'/resetting'})
	  }
  }
};
</script>

<style scoped>
	.remove_data{
		position: absolute;
		    top: 4.5rem;
		    left: 80%;
	}
	.remove_data a{
		color: #fff;
	}
.bottom {
    width: 100%;
    /* height: 420px; */
    background-color: rgb(53, 52, 52);
    margin: 0 auto 0;
  }
  .bottom_A {
    height: 180px;
    /* height: 240px; **/
    color: aliceblue;
    /* background-color: red; */
    border-bottom: 1px solid rgb(85, 80, 80);
  }
  .bottom_A .A_column {
    margin-left: 400px;
    /* height: 240px; **/
  }
  .A_column .titleBox {
    position: relative;
    float: left;
    width: 284px;
    text-align: center;
    /* margin-top: 50px; **/
    margin-top: 30px;
  }
  .A_column img {
    margin-top: 20px;
    /* margin-top: 50px; **/
    width: 150px;
    height: 150px;
  }
  .titleBox .content {
    font-size: 12px;
    margin: 10px auto;
    width: 64px;
    text-align: left;
  }
  .content li,
  .more li {
    margin-bottom: 5px;
    color: aliceblue;
  }
  .titleBox .more {
    font-size: 12px;
    float: right;
    position: absolute;
    right: 40px;
    top: 31px;
  }
  .bottom_B {
    position: relative;
    color: aliceblue;
    /* height: 180px; **/
    height: 120px;
    display: flex;
    justify-content: center;
    /* background-color: green; */
  }
  
  .bottom_B .state {
    margin: 20px 0;
    font-size: 10px;
  }
  .state li {
    float: left;
  }
  .state li:not(:last-child):after {
    content: "|";
    margin: 0 5px;
    display: inline-block;
  }
  
  .bottom_B .baseinfo {
    font-size: 10px;
    display: flex;
    position: absolute;
    /* top: 50px; **/
    bottom: 20px;
    left: 600px;
  }
  .baseinfo img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .baseinfo .info {
    margin: 10px;
  }
  .link{
	  cursor: pointer;
  }
</style>
