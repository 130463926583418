<template>
  <div id="tt">
    <Top :navArr="navArr"></Top>
    <!-- <div class="poster">
      <img src="../../../assets/WH.png" alt="" />
    </div> -->
    <div class="article news">
        	<div class="news_box">
          <div class="title">
            <h3>News</h3>
          </div>
          <ul>	
            <li v-for="(news,key,index) in NewsList" :key="index" v-show="news.show == 1">
        	  <div class="link" >
                <div class="info">
                  <div class="detail" @click="toArticle(news.id)">
                    <span class="news_title"><b>{{ news.title }}</b></span>
                    <div>
						<img class="preview" :src="`${baseURL}${news.preview}`" alt="">
						<span class="summary">{{ news.summary }}</span>
					</div>
                    <span class="time">{{ news.created_at }}</span>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          </div>
    </div>
	<Bottom></Bottom>
  </div>
</template>

<script>
import Top from "@/components/MobileTopCp.vue";
import Bottom from "@/components/MobileBottom.vue";
import { mapState } from "vuex";
export default {
  name: "Article",
  components: { Top, Bottom },
  data(){
    return{
        navArr: [
        {
          name: "Home",
          path: "/home",
        },
      ],
    }
  },
  mounted() {
      this.$store.dispatch("reqNews");
	      document.querySelector('#tt').scrollIntoView({
	          behavior: "smooth",
	          block: "start"
	      });
		  
  },
  methods: {
	  //最新动态详情
	  toArticle(id) {
	    this.$router.push({ path: `/article/${id}`, query: id });
	    this.$store.dispatch("NewsDetailList", id);
	  },
  },

  computed:{
    ...mapState({
      baseURL: (state) => state.baseURL,
      NewsList: (state) => state.NewsList,
    }),
  },
};
</script>

<style scoped>
	
.app .top{
	background-color: #fff;
}
.poster {
 
  background-color: grey;
}
.article{
	position: relative;
	background-image: url("@/assets/mobile/bg.jpg");
	background-size: 100% auto;
	margin-bottom:1rem;
	padding-top: 4rem;
}
.article .info{
    height: 100%;
    //background-color: rgb(187, 185, 185);
    margin: 0 auto;
    padding-top: 10px;
}


.news {
  width: 100%;
  position: relative;
}
.news_box{
	width: 95%;
	/* background-color: rgb(34, 133, 172); */
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	height: auto;
	display: block;
}
.news .title {
  /* height: 50px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 91%;
  border-left: 0.5rem solid #22fdf9;
  border-right: 0.5rem solid #22fdf9;
  margin: 0px 3%;
}
.news .title h3{
	background-color: #fff;
	width: 10rem;
	height: 3rem;
	line-height: 3rem;
	padding-left: 1rem;
	background-image: url('../../../assets/mobile/news_title_bg.png');
}
.news_img{
  width: 97%;
  padding: 1.5%;
}
.news_img img{
	width: 100%;
}
.news ul {
	/* position: absolute; */
}
.news ul li {
      width: 95%;
      /* background-color: rgb(34, 133, 172); */
      margin: 1rem auto;
      box-sizing: border-box;
      position: relative;
      background-color: #fff;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
      border-radius: 0.5rem;
      height: auto;
      display: block;
}
.news .link {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.link .info {
  /* display: flex; */
}
.link .detail {
	display: inline-block;
	font-size: 16px;
	flex-direction: column;
	width: 86%;
	margin: 0.2rem 7%;
}
.link .detail span{
	padding: 0.2rem 0;
	display: block;
}
.detail .news_title{
	
}
.detail .time {
  font-size: 12px;
  color: grey;
  float: right;
}
.news_move{
	position: absolute;
	bottom: 4rem;
	left: 50%;
	width: 7rem;
	margin-left: -3.5rem;
	height: 2.5rem;
	border-radius: 2rem;
	font-size: 16px;
	background-color: #8cfaf8;
}
.detail .summary {
  font-size: 14px;
  color: rgb(54, 54, 54);
}
.info .Picbox {
  display: inline-block;
  width: 300px;
  height: 160px;
  overflow: hidden;
  border-radius: 8px;
  vertical-align: top;
}
/* .link .info img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
} */
.link .info img:hover {
  transform: scale(1.3);
}
.preview{
	width: 35%;
	float: left;
	margin-right: 3px;
}
</style>
