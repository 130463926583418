<template>
  <div id="tt">
    <Top :navArr="navArr"></Top>
    <!-- <div class="poster">
      <img src="../../../assets/WH.png" alt="" />
    </div> -->
    <div class="article">
        <div class="info">
			<img :src="`${baseURL}${NewSDetailList.preview}`" alt="">
            <h3 class="title">{{ NewSDetailList.title }}</h3>
            <div v-html="NewSDetailList.content"></div>
        </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from "@/components/MobileTopCp.vue";
import Bottom from "@/components/MobileBottom.vue";
import { mapState } from "vuex";
export default {
  name: "Article",
  components: { Top, Bottom },
  data(){
    return{
        navArr: [
        {
          name: "Home",
          path: "/home",
        },
        // {
        //   name: "Product",
        //   path: "/products",
        // },
        {
          name: "News",
          path: "/Articlelist",
        },
      ],
    }
  },
  mounted() {
	      document.querySelector('#tt').scrollIntoView({
	          behavior: "smooth",
	          block: "start"
	      });
  },
  
  computed:{
    ...mapState({
        baseURL: (state) => state.baseURL,
        NewSDetailList: (state) => state.NewSDetailList,
    }),
  }
};
</script>

<style scoped>
	
.app .top{
	background-color: #fff;
}
.poster {
 
  background-color: grey;
}
.article{
	position: relative;
	top: 3.5rem;
  background-image: url("@/assets/mobile/bg.jpg");
  background-size: 100% auto;
}
.article .info{
    /* width: 1200px; */
    height: 100%;
    //background-color: rgb(187, 185, 185);
    margin: 0 auto;
    padding-top: 10px;
}
.article .info img{
	width: 100%;
}
</style>
