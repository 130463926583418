<template>
  <div class="container" id="amailContainer" :style="{ height: containerHeight + 'px' }">
    <Top :navArr="navArr"></Top>
		<!-- tab
		基本信息
		修改密码
		安全设置
		日志
		游戏信息
		-->
	<div class="center">
		<ul class="tab">
			<li :class="{ 'active': tab==1 }" @click="tabChange(1)">基本信息</li>
			<li :class="{ 'active': tab==2 }" @click="tabChange(2)">修改密码</li>
			<li :class="{ 'active': tab==3 }" @click="tabChange(3)">安全设置</li>
			<li :class="{ 'active': tab==4 }" @click="tabChange(4)">操作日志</li>
			<li :class="{ 'active': tab==5 }" @click="tabChange(5)">游戏信息</li>
		</ul>
		<!-- content -->	
		<div class="content">
			<!-- 基本信息 -->
			<div v-show="tab==1" class="content_item info">
				<div>
					<h2 class="input-box">账号信息</h2>
					<div class="input-box">
						通行证ID：<span>{{info.token}}</span>
					</div>
					<div class="input-box">
						邮箱：<span>{{info.mail}}</span>
					</div>
				</div>
			</div>
			<!-- 修改密码 -->
			<div v-show="tab==2" class="content_item password">
				<div class=" mail-login" >
					<div class="main">
						<div class="mail-logo">
							<img src="../../../assets/GalacticSpark_logo.png" class="login-logo" />
						</div>
						<div class="input-box">
							<input type="text" name="mail" class="mail" placeholder="Email" v-model="passwordBox.mail"> <button class="send_code" @click="sendCode()">Send</button>
						</div>
						<div class="input-box">
							<input type="text" name="code"  placeholder="Code" v-model="passwordBox.code">
						</div>
						<div class="input-box">
							<input type="password"  name="password"   placeholder="Password" v-model="passwordBox.password">
						</div>
						<div class="input-box">
							<input type="password"  name="re_password"   placeholder="Confirm Password" v-model="passwordBox.re_password">
						</div>
						
						<div class="box2">
							<div class="remember">
							<input type="checkbox" name="remember_me" id="remember_me" v-model="passwordBox.remember_me">
							<label for="remember_me">Remember me</label>
							</div>
						</div>
						<div><button class="login" @click="onChangePassword()">Change</button></div>
						<div class="box4">
							<p>Don't have a account? <a href="#" id="signupBtn" @click="showSignup()">Sign up now!</a></p>
							<p>Have a account? <a href="#" @click="showLogin()">Login!</a></p>
						</div>
					</div>
				</div>
			</div>
			<!-- 安全设置 -->
			<div v-show="tab==3" class="content_item set">
				
				<h2 class="input-box">Account Security</h2>
				<div class="input-box">
					verify your Gtarcade account <span>{{set.account}}</span>
				</div>
				<div class="input-box">
					Password Last update time: <span>{{set.changeTime}}</span>
					Changing passwords will usually make your account more secure
				</div>
				<div class="input-box">
					Bind your email Your email: <span class="orange">{{set.mail}}</span>
					Bind your email, so we can help if you forget your username or password

				</div>
			</div>
			<!-- 操作日志 -->
			<div v-show="tab==4" class="content_item log">
				
				<h2 class="input-box">My Messages</h2>
				<table>
					<thead>
						<th>source</th>
						<th>operation</th>
						<th>Region</th>
						<th>Date</th>
					</thead>
					<tbody v-if="log.data == null">No records found</tbody>
					<tbody v-if="log.data != null">
						<tr v-for="(item,log_key) in log.data" :class="{ 'dual': log_key%2 == 0 }">
							<td>{{item.source}}</td> <!--来源-->
							<td>{{item.action}}</td> <!--操作-->
							<td>{{item.ip}}</td> <!--区域-->
							<td>{{item.created_at}}</td> <!--日期-->
						</tr>
					</tbody>
				</table>
				<div class="page">
					<ul>
						<li class="previous_page" @click="turning(log.page-1)">上一页</li>
						<li v-for="(page,index) in log.page_arr" :class="{ 'active': page==log.page }" @click="turning(page)">{{page}}</li>
						<li class="next_page" @click="turning(log.page+1)">下一页</li>
					</ul>
				</div>
			</div>
			<!-- 游戏信息 -->
			<div v-show="tab==5" class="content_item my_game">
				<h2 class="input-box">My Game</h2>
				<div class="input-box" v-for="gameItem in game">
					<div><img :src="gameItem.img" alt=""></div>
					<div>{{gameItem.name}}</div>
				</div>
			</div>
		</div>
		</div>
	</div>
</template>

<script>
import * as swiperAni from "@/animate";
import "../../../../public/animate.min.css";
import Swiper from "swiper/bundle";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { mapState } from "vuex";
import $ from 'jquery';





export default {
	
  name: "Mailaccount",
  components: { Top, Bottom },
  data() {
    return {
	  apiUrl:'https://p03vcenter.wenhaogame.com',
      swiper: {},
      page: 1,
      navArr: [
        {
          name: "HOME",
          path: "/home",
        },
      ],
	  containerHeight:1,
	  tab:1,
	  token:'',
	  info:{
		  token:'',
		  mail:'',
	  },
	  set:{
		  account:'',
		  changeTime:'',
		  mail:'',
	  },
	  passwordBox:{
		  mail:'',
		  code:'',
		  password:'',
		  re_password:'',
		  remember_me:true,
	  },
	  log:[
		  
	  ],
	  game:[
		  
	  ],
	  
	};
  },	
  mounted(){
	  this.containerHeight =  document.documentElement.clientHeight;
	  this.token = localStorage.getItem('token');
	  if(!this.token){
		 
		  this.$router.push({path:'/'})
	  }
	  var that = this
	  $.ajax({
	  		  method:'POST',
	  		  dataType: 'text',
	  		  data:{token:this.token},
	  		  jsonpCallback: "jsonpcallback",
	  		  url: this.apiUrl+'/account/account_info',
	  		  success: function (res) {
	  			  res =  JSON.parse(res)
	  			  if(res.code == 0){
	  				that.info.token = res.data.token
	  				that.info.mail = res.data.mail
	  				that.set.account = res.data.mail
	  				that.set.mail = res.data.mail
	  				that.set.changeTime = res.data.updated_at
	  			  }
	  		  },
	  })
	  
	  
	  $.ajax({
	  		  method:'POST',
	  		  dataType: 'text',
	  		  data:{token:this.token},
	  		  jsonpCallback: "jsonpcallback",
	  		  url: this.apiUrl+'/account/account_log',
	  		  success: function (res) {
	  				  res =  JSON.parse(res)
	  			  if(res.code == 0){
	  					this.log = res.data;
	  					var page_arr = [];
	  					for(var i = 1; i <= this.log.page_num;i++){
	  						page_arr.push(i)
	  					}
	  					this.log.page_arr = page_arr
	  			  }
	  		  },
	  })
	  $.ajax({
	  		  method:'POST',
	  		  dataType: 'text',
	  		  data:{token:this.token},
	  		  jsonpCallback: "jsonpcallback",
	  		  url: this.apiUrl+'/account/account_game',
	  		  success: function (res) {
	  				  res =  JSON.parse(res)
	  			  if(res.code == 0){
	  			  				this.game = res;
	  			  }
	  		  },
	  })
  },
  methods: {
	  tabChange(num){
	     		  this.tab = num
	  },
	  sendCode(){
	  	$.ajax({
	  			  method:'POST',
	  			  dataType: 'text',
	  			  data:{mail:this.passwordBox.mail},
	  			  jsonpCallback: "jsonpcallback",
	  			  url: this.apiUrl+'/account/send_change_password_code',
	  			  success: function (res) {
	  					  res =  JSON.parse(res)
	  				  if(res.data.code == 0){
	  				  	alert('success');
	  				  }else{
	  				  	alert('error');
	  				  }
	  			  },
	  	})
	  },
	  onChangePassword(){
	  	if(!this.passwordBox.code){
	  		alert('code is null');return false; 
	  	}
	  	if(this.remember_me){
	  			localStorage.setItem('mail',this.passwordBox.mail);	  
	  			localStorage.setItem('password',this.passwordBox.password);	    
	  	}else{
	  		localStorage.removeItem("mail");
	  		localStorage.removeItem("password");
	  	}
	  	
	  	$.ajax({
	  		  method:'POST',
	  		  dataType: 'text',
	  		  data:{mail:this.passwordBox.mail,code:this.passwordBox.code,password:this.passwordBox.password,re_password:this.passwordBox.re_password},
	  		  jsonpCallback: "jsonpcallback",
	  		  url: this.apiUrl+'/account/change_password',
	  		  success: function (res) {
	  			  res =  JSON.parse(res)
	  			  if(res.code == 0){
	  				alert('success');
	  				localStorage.removeItem("token");
	  				this.$router.push({path:'/'})
	  			  }else{
	  				alert('error');
	  			  }
	  		  },
	  	})
	  },
	  turning(page){
		  if(page < 1 || page > this.log.page_num){
			  return false;
		  }
		  $.ajax({
		  	  method:'POST',
		  	  dataType: 'text',
		  	  data:{token:this.token,page:page},
		  	  jsonpCallback: "jsonpcallback",
		  	  url: this.apiUrl+'/account/account_log',
		  	  success: function (res) {
		  				  res =  JSON.parse(res)
		  		  this.log = res.data;
		  		  var page_arr = [];
		  		  for(var i = 1; i <= this.log.page_num;i++){
		  		  	page_arr.push(i)
		  		  }
		  		  this.log.page_arr = page_arr
		  	  },
		  })
		  
	  },
  },
  computed: {
   
   	  
   
  },
  watch: {
    
  },
};
</script>

<style scoped>
	
	#amailContainer{
		margin-top: 5rem;
	}

.container {
  background-image: url("@/assets/GalacticSpark_bg.png");
  background-size: auto 100%;
  height: 100%;
  text-align: center;
}
.center{
	background-color: #fff;
	display: flex;
}
.tab{
	/* text-align: right; */
	width: 15%;
	margin: 1rem 0;
	display: inline-block;
	vertical-align: top;    
	float: left;
}
.tab li{
	padding: 0.5rem 0;
	height: 2rem;
	line-height: 2rem;
	text-align: center;    
	width: 100%;
}
.tab .active{
	background-color: #f8f8f8;
	border-left: 3px #000000 solid;
}
.tab li:hover{
	background-color: #f8f8f8;
}
.content{
	width: 80%;
	display: inline-block;
	height: 100rem;
	border-left: 1px #989898 solid;
	float: left;
}
.content .content_item{
	text-align: left;
}

.mail-login{
	width: 30rem;
	padding: 2rem;
	background-color: #fff;
	color: #989898;
	padding-top: 5rem;
	text-align: center;
}




.login-logo{
	position: absolute;
	top: 0px;
	left: 50%;
	width: 10rem;
	margin-left: -5rem;
	margin-top: -5rem;
}
.input-box{
	display: inline-block;
	padding: 0.5rem 10%;
	width: 80%;
	
}

.input-box input{
	float: right;
	width: 98%;
	height: 2rem;
	padding-left: 2%;
}
.box2{
	display: inline-block;
	padding: 0 10%;
	width: 80%;
	font-size: 12px;
}
.box2 .remember{
	float: left;
}
.box2 .forgot{
	float: right;
	color: #fe9500;
}
.login{
	width: 80%;
	height: 2.5rem;
	margin: 3%;
	font-size: 22px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 5px;
}
.box4{
	font-size: 12px;
}
.box4 p{
	margin-top: 0.3rem;
}
.box4 a{
	color: #fe9500;
}
.main .input-box .mail{
	width: 60%;
	float: left;
	vertical-align: top;
}
.send_code{
	width: 36%;
	height: 2.25rem;
	font-size: 22px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 2px;
}
.orange{
	color:orange;
}
.dual{
	background-color: #f8f8f8;
}

.log th{
	text-align: center;
}
.log td{
	padding: 0rem 3rem;
	text-align: center;
	width: 15rem;
}
.my_game img{
	width: 10rem;
	height: 10rem;
}
.page{
	
}
.page ul{
	display: flex;
}
.page ul li{
	padding: 1px 6px;
	border: 1px solid;
	margin-left: 5px;
	cursor: pointer;
}
.page ul .active{
	background-color: #fe9500;
	color: #fff;
}

</style>
