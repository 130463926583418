<template>
	<div class="container" id="smailContainer">
		<div class=" mail-login" >
			<div class="main">
				<div class="mail-logo">
					<img src="../../../assets/GalacticSpark_logo.png" class="login-logo" />
				</div>
				<div class="input-box">
					<input type="text" name="mail" class="mail" placeholder="Email" v-model="mail">  <button class="send_code" @click="sendCode()">Send</button>
				</div>
				<div class="input-box">
					<input type="text" name="code"  placeholder="Code" v-model="code">
				</div>
				<div class="input-box">
					<input type="password"  name="password"   placeholder="Password" v-model="password">
				</div>
				<div class="input-box">
					<input type="password"  name="re_password"   placeholder="Confirm Password" v-model="re_password">
				</div>
				<div class="box2">
					<div class="remember">
					<input type="checkbox" name="remember_me" id="remember_me"  v-model="remember_me">
					<label for="remember_me">Remember me</label>
					</div>
					<a href="#" class="forgot" @click="showPassword()">Forgot Password?</a>
				</div>
				<div><button class="login" @click="onRegister()">Log Up</button></div>
				<div class="box4">
					<p>Have a account? <a href="#" @click="showLogin()">Login!</a></p>
					<p>Forgot your login email? <a href="#" @click="showPassword()">Recover account</a></p>
				</div>
				
				
			</div>
		</div>
	</div>
	
	
	
	
</template>

<script>
import * as swiperAni from "@/animate";
import "../../../../public/animate.min.css";
import Swiper from "swiper/bundle";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { mapState } from "vuex";
import $ from 'jquery';

import {reqMailSendRegisterCode} from '../../../api/index'
export default {
	
  name: "Maillogin",
  components: { Top, Bottom },
  loginShow:true,
  data() {
    return {
	  apiUrl:'https://p03vcenter.wenhaogame.com',
      swiper: {},
      page: 1,
      navArr: [
        {
          name: "HOME",
          path: "/home",
        },
      ],
		containerHeight:1,
		mail:'',
		code:'',
		password:'',
		re_password:'',
		remember_me:true,
	};
	
  },
  mounted(){
	  //this.$store.commit("MAILSENDREGISTERCODE")
	  this.containerHeight =  document.documentElement.clientHeight;
  },
  methods: {
	sendCode(){
		   
		   
		$.ajax({
			  method:'POST',
			  dataType: 'text',
			  data:{token:this.token,server_id:this.server_id},
			  jsonpCallback: "jsonpcallback",
			  url: this.apiUrl+'/account/send_register_code',
			  success: function (res) {
				  res =  JSON.parse(res)
				 
				  if(res.code == 0){
					alert('success');
				  }else{
					alert('error');
				  }
			  }
		})
	},
	onRegister(){
		if(!this.code){
			alert('code is null');return false; 
		}
		if(this.remember_me){
			localStorage.setItem('mail',this.mail);	  
			localStorage.setItem('password',this.password);	    
		}else{
			localStorage.removeItem("mail");
			localStorage.removeItem("password");
		}
		
		axios.post(this.apiUrl+'/account/register',
		{mail:this.mail,code:this.code,password:this.password,re_password:this.re_password},
		{'emulateJSON': true, 'credentials': true}
		).then(res=>{
			if(res.data.code == 0){
				if(res.data.data.token){
					localStorage.setItem('token',res.data.data.token);
					this.$parent.logined()
				}
				alert('success');
			}else{
				alert('error');
			}
		})
	},
	showLogin(){
		this.$parent.showLogin()
	},
	showPassword(){
		this.$parent.showPassword()
	},
  },
  computed: {
	// ...mapState({
 //     mailSendRegisterCode:(state) => state.mailSendRegisterCode,
 //   }),
   
  },
  watch: {
    
  },
};
</script>

<style scoped>
#smailContainer{
	margin-top: 5rem;
}
.mail-login{
	position: relative;
	top:200%;
	left:50%;
	margin-left: -15rem;
	width: 30rem;
	padding: 2rem;
	background-color: #fff;
	color: #989898;
	padding-top: 5rem;
}


.main{
	position: relative;
	text-align: center;
}

.login-logo{
	position: absolute;
	top: 0px;
	left: 50%;
	width: 10rem;
	margin-left: -5rem;
	margin-top: -5rem;
}
.input-box{
	display: inline-block;
	padding: 0.5rem 10%;
	width: 80%;
	
}

.input-box input{
	float: right;
	width: 98%;
	height: 2rem;
	padding-left: 2%;
}
.box2{
	display: inline-block;
	padding: 0 10%;
	width: 80%;
	font-size: 12px;
}
.box2 .remember{
	float: left;
}
.box2 .forgot{
	float: right;
	color: #fe9500;
}
.login{
	width: 80%;
	height: 2.5rem;
	margin: 3%;
	font-size: 22px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 5px;
}
.box4{
	font-size: 12px;
}
.box4 p{
	margin-top: 0.3rem;
}
.box4 a{
	color: #fe9500;
}
.main .input-box .mail{
	width: 60%;
	float: left;
	vertical-align: top;
}
.send_code{
	width: 36%;
	height: 2.25rem;
	font-size: 22px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 2px;
}
</style>
