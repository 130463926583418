<template>
  <div class="bottom">
    <div class="bottom_A">
        <img src="../assets/logo.png">
        <span>xxxxxxxxxxxxx</span>
    </div>
    <div class="bottom_B">
        <img src="../assets/logo.png">
        <span>xxxxxxxxxxxxx</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  data() {
    return {
      bottomArr: [
        {
          title: "问皓产品",
          other: [
            {
              name: "开心打泡泡",
              path: "/",
            },
            {
              name: "怪物城堡",
              path: "/",
            },
            {
              name: "招聘资讯",
              path: "/",
            },
            {
              name: "泡泡打怪物",
              path: "/",
            },
            {
              name: "开心怪物",
              path: "/",
            },
          ],
        },
        {
          title: "关于问皓",
          other: [
            {
              name: "问皓简介",
              path: "/about",
            },
            {
              name: "发展历程",
              path: "/about",
            },
          ],
        },
        {
          title: "加入问皓",
          other: [
            {
              name: "社会招聘",
              path: "/social",
            },
            {
              name: "校园招聘",
              path: "/school",
            },
          ],
        },
        {
          title: "商务合作",
          other: [
            {
              name: "联系我们",
              path: "/",
            },
          ],
        },
      ],
      state: [
        "法律声明",
        "纠纷处理",
        "用户协议",
        "隐私政策",
        "儿童个人信息保护规则",
      ],
      baseInfo: [
        "官网地址: xxxxxxxx",
        "联系电话: xxxxxxxx",
        "公司地址: xxxxxxxx",
        "版权信息: xxxxxxxx",
        "备案号: xxxxxxxx",
      ],
    };
  },
};
</script>

<style scoped>
.bottom {
  width: 100%;
  background-color: rgb(53, 52, 52);
  margin: 50px auto 0;
}
.bottom_A {
  height: 80px;
  color: aliceblue;
  /* border-bottom: 1px solid rgb(85, 80, 80); */
  /* background-color: rgb(168, 45, 45); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom_A img{
    width: 25px;
    height: 25px;
}
.bottom_B {
  position: relative;
  color: aliceblue;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom_B img{
    width: 25px;
    height: 25px;
}
</style>
