<template>
  <div class="Bg">
    <Top :navArr="navArr"></Top>
    <div class="OutContainer">
      <div class="InContainer">
        <!-- 标题栏 -->
        <div class="title-box">
          <h3>资深广告视频设计</h3>
          <div class="detail">
            <div class="classify">
              {{ JobDetail.classify }}<span class="line">|</span
              >{{ JobDetail.name }}
            </div>
            <div>{{ JobDetail.local }}</div>
          </div>
        </div>
        <!-- 职位描述 -->
        <div class="job_info">
          <div class="top">
            <span>职位描述</span>
            <div class="line"></div>
          </div>
          <div class="content">
            <span>岗位职责：</span>
            <div v-html="JobDetail.duty"></div>
            <span>岗位要求：</span>
            <div v-html="JobDetail.need"></div>
          </div>
          <div class="top">
            <span>职位信息</span>
            <div class="line"></div>
          </div>
          <div class="number">
            <span class="words">招聘人数</span>
            <span>{{ JobDetail.amount }}人</span>
          </div>
        </div>
        <!-- 邮箱地址 -->
        <div class="address">
          <span>邮箱:HR@junrenwl.com</span>
        </div>
      </div>
    </div>
    <RecBottom></RecBottom>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import { mapState } from "vuex";
import RecBottom from "@/components/RecBottom.vue";
export default {
  name: "SocialDetail",
  components: { Top, RecBottom },
  data() {
    return {
      navArr: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "招聘职位",
          path: "/add",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      JobDetail: (state) => state.JobDetail,
    }),
  },
};
</script>

<style scoped>
.Bg {
  background-color: rgb(211, 208, 208);
}
.OutContainer {
  width: 100%;
  min-height: 730px;
  padding-top: 25px;
  box-sizing: border-box;
}
.OutContainer .InContainer {
  width: 1000px;
  margin: 0 auto;
  background-color: #fff;
  padding: 0 30px 30px;
  box-sizing: border-box;
}
.title-box {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: red; */
}
.title-box h3 {
  margin-bottom: 20px;
}
.title-box .detail {
  color: rgb(99, 98, 98);
  font-size: 14px;
}
.detail .classify {
  padding-bottom: 10px;
}
.classify span {
  margin: 0 10px;
}
.job_info .top {
  height: 30px;
  /* background-color: rgb(167, 99, 99); */
  padding-bottom: 15px;
  display: flex;
  align-items: center;
}
.job_info .content {
  font-size: 14px;
}
.job_info .line {
  width: 860px;
  height: 1px;
  background-color: rgb(218, 218, 218);
  margin-left: 10px;
}
.job_info .number {
  display: flex;
  flex-direction: column;
}
.number .words {
  color: rgb(99, 98, 98);
}
.address {
  background-color: rgb(211, 208, 208);
  width: 200px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin: 0 auto;
}
</style>
