<template>
  <div>
    <Top :navArr="navArr"></Top>
    <!-- <div class="poster">
		
    </div> -->
   <div class="article">
   	<div class="info">
   		<h3 class="title">{{ NewSDetailList.title }}</h3>
   		<div v-html=" NewSDetailList.content"></div>
   	</div>
   </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { mapState } from "vuex";
export default {
  name: "Article",
  components: { Top, Bottom },
  data(){
    return{
        navArr: [
       {
         name: "HOME",
         path: "/home",
       },
      ],
	  id:0,
    }
  },
  
  mounted() {
	  console.log(this.$route.query.id)
		this.id = this.$route.query.id
      this.$store.dispatch("NewsDetailList", this.id);
  },
  computed:{
    ...mapState({
        NewSDetailList: (state) => state.NewSDetailList,
    }),
  }
};
</script>

<style scoped>
.poster {
  width: 1920px;
  height: 200px;
  background-image: url('../../../assets/WH.jpg');
}
.poster img{
	width: 100%;
}
.article{
    width: 1920px;
    height: 1200px;
    background-color: rgb(202, 200, 200);
  background-image: url('../../../assets/WH.jpg');
  background-size: 100%;
}
.article .info{
    width: 1200px;
    height: 100%;
    background-color: #fff;
	/* rgb(187, 185, 185); */
    margin: 0 auto;
    padding-top: 10px;
}
</style>
