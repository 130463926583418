<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'App',
  data() {
    return {
		
		token:'',
    };
  },
  // created(){
  //   if(this.$router.path !== '/home'){
  //     this.$router.replace({
  //       name:'home'
  //     })
  //   }
  // }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
  color: black;
}
.app{
  width: 100%;
  background-image: url("@/assets/bg.png");
}
</style>
