<template>
  <div>
    <!-- 导航栏Top -->
    <div class="topContainer">
      <div class="top">
        <div class="logo-box">
          <router-link to="/home">
            <img src="../../../assets/logo.png" class="logo" />
          </router-link>
        </div>
        <ul class="nav">
          <li v-for="(nav, index) in navArr" :key="index">
            <div
              @click="toLocal(nav.id)"
              :class="{ active: nav.id === local }"
              class="link"
            >
              {{ nav.name }}
            </div>
          </li>
        </ul>
      </div>
    </div>
     <div class="center">
      <img src="../../../assets/school.jpg" class="mainpic">
      <div class="box1" @click="topage(2)"></div>
      <div class="box2" @click="topage(2)"></div>
      <div class="box3" @click="topage(2)"></div>
      <div class="box4" @click="topage(2)"></div>
      <div class="box5" @click="topage(2)"></div>
      <div id="know" class="know"></div>
      <div id="recruit" class="recruit"></div>
      <div id="about" class="about"></div>
      <div id="add" class="add"></div>
     </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { mapState } from "vuex";

export default {
  name: "School",
  components: { Top, Bottom },
  data() {
    return {
      local:"home",
      liIndex: -1, //标记触发哪个li标签
      navArr: [
        {
          name: "首页",
          id: "home",
        },
        {
          name: "认识问皓",
          id: "know",
        },
        {
          name: "校招资讯",
          id: "recruit",
        },
        {
          name: "我在问皓",
          id: "about",
        },
        {
          name: "加入问皓",
          id: "add",
        },
      ],
    };
  },
  methods: {
    toLocal(id){
      document.getElementById(id).scrollIntoView()
      this.local = id
    },
    topage(id){
      this.$router.push({path:`/socialjob/sort_id=${id}`,query:id})
      this.$store.dispatch("JobsList",id)
    },
  },
  computed: {
    ...mapState({
      baseURL: (state) => state.baseURL,  
      ProductsList: (state) => state.ProductsList,
    }),
  }
};
</script>

<style scoped>
.center{
  position: relative;
}
.center .box1{
  width: 500px;
  height: 130px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:940px;
  left: 190px;
  cursor: pointer;
}
.center .box2{
  width: 500px;
  height: 130px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:940px;
  left: 730px;
  cursor: pointer;
}
.center .box3{
  width: 500px;
  height: 130px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:940px;
  right: 140px;
  cursor: pointer;
}
.center .box4{
  width: 500px;
  height: 130px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:1130px;
  left: 190px;
  cursor: pointer;
}
.center .box5{
  width: 500px;
  height: 130px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:1130px;
  left: 730px;
  cursor: pointer;
}
.center .know{
  width: 500px;
  height: 10px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:2100px;
}
.center .recruit{
  width: 500px;
  height: 10px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:1330px;
}
.center .about{
  width: 500px;
  height: 10px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:5100px;
}
.center .add{
  width: 500px;
  height: 10px;
  /* background-color: rgb(107, 40, 40); */
  position: absolute;
  top:6150px;
}
.topContainer {
  height: 100px;
}
.top {
  width: 100%;
  height: 100px;
  background-color: rgba(31, 31, 30, 0.9);
  z-index: 99999;
  position: fixed;
  color: aliceblue;
}
.top .logo-box {
  float: left;
}
.top .logo-box .logo {
  width: 100px;
  height: 100px;
  margin-left: 300px;
}
.nav {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 50px;
  height: 98px;
}
.nav li .link {
  height: 98px;
  line-height: 98px;
}
.nav li:hover {
  cursor: pointer;
  font-size: 18px;
  color: rgb(219, 109, 6);
  border-bottom: 4px solid rgb(219, 109, 6);
}
.active {
  color: rgb(219, 109, 6);
}

.mainpic{
  width: 1903px;
}
</style>
