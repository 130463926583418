<template>
  <div class="Bg">
    <Top :navArr="navArr"></Top>
    <div class="OutContainer">
      <div class="InContainer">
        <div class="jobInfo">
          <!-- 职位筛选区 -->
          <div class="choice">
            <span>职位筛选</span>
            <!-- 搜索栏 -->
            <div class="search-box">
              <input
                type="text"
                placeholder="输入职位关键字"
                v-model="job"
                maxlength="150"
              />
              <div class="searchButton">搜索职位</div>
            </div>
          </div>
          <!-- 职位列表区 -->
          <div class="jobLists">
            <!-- 工作地点 -->
            <div class="local_info">
              <span class="title">工作地点:</span>
              <SelectLocal class="select"></SelectLocal>
            </div>
            <!-- 职能类型 -->
            <div class="types_info">
              <span class="title">职位类型:</span>
              <ul class="jobs">
                <li v-for="(t, index) in types" :key="index">
                  <span
                    :class="{ active: typesValue == t.name }"
                    @click="addClass(t.name)"
                    >{{ t.name }}</span
                  >
                </li>
              </ul>
            </div>
            <!-- 职位性质 -->
            <div class="classify_info">
              <span class="title">职位性质:</span>
              <ul>
                <li v-for="(c, index) in classify" :key="index">
                  <span
                    :class="{ active: classifyValue == index }"
                    @click="ClassifyClass(index)"
                    >{{ c }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- 岗位详细信息 -->
        <div class="detail">
          <ul>
            <li v-for="job in JobsList" :key="job.id">
              <div class="content" @click="toDetail(job.id)">
                <div class="title">{{ job.name }}</div>
                <div class="classify">
                  {{ job.classify }}<span class="line">|</span>
                  {{typesValue}}类
                </div>
                <div>{{ job.local }}</div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 页数 -->
        <div class="page">
          <el-pagination layout="prev, pager, next" :total="50" small>
          </el-pagination>
        </div>
      </div>
    </div>
    <RecBottom></RecBottom>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import SelectLocal from "@/components/SelectLocal.vue";
import { mapState } from "vuex";
import RecBottom from "@/components/RecBottom.vue";
export default {
  name: "SocialJob",
  components: { Top, SelectLocal, RecBottom },
  data() {
    return {
      navArr: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "招聘职位",
          path: "/add",
        },
      ],
      job: "",
      types: [
        {
          name:"研发技术",
        },
        {
          name:"用户运营类",
        },
        {
          name: "手游运营类",
        },
        {
          name: "游戏直播类",
        },
        {
          name:  "市场商务类",
        },
        {
          name: "美术美工",
        }, 
      ],
      classify: ["全职", "兼职", "学习", "其他"],
      typesValue:"研发技术",
      classifyValue: 0,
    };
  },
  mounted(){
    this.typesValue = this.$route.query.name
  },
  methods: {
    addClass(name) {
      this.typesValue = name;
      this.$store.dispatch('SearchJob',name)
    },
    ClassifyClass(index) {
      console.log(index);
      this.classifyValue = index;
    },
    toDetail(id) {
      console.log(">>>>>>>>>>");
      this.$router.push({ path: `/socialdetail/id=${id}`, query: id });
      this.$store.dispatch("JobDetail", id);
    },
  },
  computed: {
    ...mapState({
      JobsList: (state) => state.JobsList,
    }),
  },
};
</script>

<style scoped>
.Bg {
  background-color: rgb(211, 208, 208);
}
.OutContainer {
  width: 100%;
  min-height: 730px;
  padding-top: 25px;
  box-sizing: border-box;
}
.OutContainer .InContainer {
  width: 1000px;
  margin: 0 auto;
}
.InContainer .jobInfo {
  padding: 20px 30px;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: 10px;
}
.jobInfo .choice {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  height: 80px;
}
.choice .search-box {
  display: flex;
}
.search-box input {
  width: 800px;
  height: 25px;
  outline: none;
  padding-left: 8px;
}
.search-box .searchButton {
  width: 100px;
  height: 29px;
  color: #fff;
  background-color: rgb(0, 0, 0);
  text-align: center;
  line-height: 29px;
}
.jobInfo  .jobLists {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  font-size: 15px;
  /* background-color: red; */
}
.jobLists .types_info,
.jobLists .classify_info,
.jobLists .local_info {
  display: flex;
}

.types_info .title,
.local_info .title,
.classify_info .title {
  margin-right: 30px;
}
.local_info .select {
  margin-top: -5px;
}
>>> .select-box[data-v-ebfb9362] {
  font-size: 15px;
  color: rgb(121, 120, 120);
}
.types_info ul li,
.classify_info ul li {
  float: left;
  margin-right: 20px;
  cursor: pointer;
  color: rgb(121, 120, 120);
}
.jobs li .active,
.classify_info li .active {
  background-color: rgb(48, 47, 47);
  color: aliceblue;
  padding: 5px 10px;
}
.InContainer .detail {
  padding: 0 30px 20px;
  box-sizing: border-box;
  background-color: #fff;
  margin-top: 10px;
}
.detail ul li {
  height: 120px;
  border-bottom: 1px solid rgb(218, 218, 218);
  cursor: pointer;
}
.detail ul li .content {
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0;
  box-sizing: border-box;
}

.content .classify {
  color: rgb(99, 98, 98);
}
.classify .line {
  margin: 0 10px;
}
.InContainer .page{
  width: 100px;

  /* background-color: red; */
  margin: 20px auto 0;
}

</style>
