<template>
  <div>
    <Top :navArr="navArr"></Top>
      <img src="../../../assets/WH.png" class="title_pic">
    <div class="products_Show">
      <ul>
        <li v-for="produces in ProductsList" :key="produces.id" v-show="produces.show == 1">
          <!-- 宣传图 -->
          <div
            class="poster"
            @mouseenter="handleEnterMouse(produces.id)"
            @mouseleave="handleLeaveMouse(produces.id)"
          >
            <img :src="`${baseURL}${produces.preview}`" class="main_pic"/>
            <!-- li里面的遮罩 -->
            <div class="animate__animated animate__fadeInDown poster_mask"  v-show="liIndex == produces.id">
              <img :src="`${baseURL}${produces.mask_icon}`" class="mask_pic"/>
              <span>类型: {{ produces.summary }}</span>
            </div>
          </div>
          <!-- 标题 -->
          <div class="title">
            <span>{{produces.title}}</span>
            <div class="type">{{ produces.summary }}</div>
          </div>
          <div class="intro">{{ produces.summary }}</div>
        </li>
      </ul>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { mapState } from "vuex";

export default {
  name: "Products",
  components: { Top, Bottom },
  data() {
    return {
      liIndex: -1, //标记触发哪个li标签
      navArr:[
        {
          name:"首页",
          path:"/home"
        },
        {
          name:"游戏产品",
          path:'/products'
        },
        {
          name:"关于问皓",
          path:'/about'
        },
        {
          name:"官方论坛",
          path:'/forum'
        },
        {
          name:"加入问皓",
          path:'/add'
        }
      ],
    };
  },
  methods: {
    handleMouse() {
      console.log("鼠标进入/移除");
      this.isMask = !this.isMask;
    },
    handleEnterMouse(id) {
      console.log("鼠标进入", id);
      this.liIndex = id;
    },
    handleLeaveMouse(id) {
      console.log("鼠标离开", id);
      this.liIndex = -1;
    },
  },
  computed: {
    ...mapState({
      baseURL: (state) => state.baseURL,  
      ProductsList: (state) => state.ProductsList,
    }),
  },
};
</script>

<style scoped>
.title_pic {
  width: 1903px;
  height: 200px;
  vertical-align: middle;
}
.products_Show {
  /* width: 1920px; */
  max-height: 1200px;
  /* background-color: rgb(19, 103, 151); */
  overflow: hidden;
}
.products_Show ul {
  width: 1240px;
  margin: 50px auto;
}
.products_Show ul li {
  position: relative;
  float: left;
  /* width: 400px; */
  width: 380px;
  /* background-color: brown; */
  margin-bottom: 20px;
  margin-right: 20px;
  padding-bottom: 10px;
}

.products_Show ul li:hover {
  transform: scale(1.01);
  box-shadow: 1px 8px 5px rgba(0, 0, 0, 0.3);
}

.products_Show .poster .main_pic {
  width: 100%;
  height: 100%;
}
.poster_mask {
  position: absolute;
  top: 0;
  width: 380px;
  height: 310px;
  background-color: rgba(19, 83, 180, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.mask_pic{
  /* width: 50px; */
  /* height: 50px; */
}
.title .type{
    display: inline-block;
    margin-left: 5px;
    font-size: 10px;
    border: 1px solid grey;
    border-radius: 8px;
    /* width:35px; */
    height: 18px;
    line-height: 18px;
    text-align: center;
    padding: 0 5px;
    background-color: #fff;
}
.products_Show .intro{
    display: inline-block;
  
}
</style>
