<template>
  <div>
    <Top :navArr="navArr"></Top>
    <div class="recruit">
      <img src="../../../assets/recruit.jpg" class="main" />
      <router-link to="/social">
        <img src="../../../assets/circle1.png" class="social"
      /></router-link>
      <router-link to="/school">
        <img src="../../../assets/circle2.png" class="school" />
      </router-link>
    </div>
    <Bottom style="margin-top: 0"></Bottom>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";

export default {
  name: "Add",
  components: { Top, Bottom },
  data() {
    return {
      navArr: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "招聘职位",
          path: "/add",
        },
      ],
    };
  },
};
</script>

<style scoped>
.recruit {
  width: 1903px;
  min-height: 730px;
  position: relative;
}
.recruit .main {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.recruit .social {
  position: absolute;
  top: 280px;
  left: 410px;
}
.recruit .school,
.recruit .social {
  position: absolute;
  top: 280px;
  right: 410px;
  animation-name: beat;
  /*动画名称*/
  animation-duration: 0.6s;
  /*设置秒数*/
  animation-timing-function: linear;
  /*速度曲线*/
  animation-iteration-count: infinite;
  /*播放次数*/
  animation-direction: alternate;
  /*逆向播放*/
  animation-play-state: running;
}
@keyframes beat {
  0% {
    top: 280px;
  }

  100% {
    top: 310px;
  }
}
</style>
>
