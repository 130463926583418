import Vue from 'vue'
import VueRouter from "vue-router";
Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

import PCHome from "../views/pc/pages/Home"
import PCAbout from "../views/pc/pages/About"
import PCProducts from "../views/pc/pages/Products"
import PCAdd from "../views/pc/pages/Add"
import PCSocial from "../views/pc/pages/Social"
import PCSchool from "../views/pc/pages/School"
import PCSocialJob from "../views/pc/pages/SocialJob"
import PCSocialDetail from "../views/pc/pages/SocialDetail"
import PCArticle from "../views/pc/pages/Article"
import PCArticlelist from "../views/pc/pages/Articlelist"
import PCForum from "../views/pc/pages/Forum"
import PCResetting from "../views/pc/pages/Resetting"
import PCMaillogin from "../views/pc/pages/Maillogin"
import PCMailaccount from "../views/pc/pages/Mailaccount"
import PCMailsignup from "../views/pc/pages/Mailsignup"
import PCMailpassword from "../views/pc/pages/Mailpassword"

import MHome from "../views/mobile/pages/Home"
import MAbout from "../views/mobile/pages/About"
import MProducts from "../views/mobile/pages/Products"
import MAdd from "../views/mobile/pages/Add"
import MSocial from "../views/mobile/pages/Social"
import MSchool from "../views/mobile/pages/School"
import MSocialJob from "../views/mobile/pages/SocialJob"
import MSocialDetail from "../views/mobile/pages/SocialDetail"
import MArticle from "../views/mobile/pages/Article"
import MArticlelist from "../views/mobile/pages/Articlelist"
import MForum from "../views/mobile/pages/Forum"
import MResetting from "../views/mobile/pages/Resetting"
import MMaillogin from "../views/mobile/pages/Maillogin"
import MMailaccount from "../views/mobile/pages/Mailaccount"
import MMailsignup from "../views/mobile/pages/Mailsignup"
import MMailpassword from "../views/mobile/pages/Mailpassword"
const routesPC = [
	{
	    name:'home',
	    path:'/',
	    redirect:'/Home'
	},
	{
	    path:'/home',
	    component:PCHome
	},
	{
	    path:'/about',
	    component:PCAbout
	},
	{
	    path:'/products',
	    component:PCProducts
	},
	{
	    path:'/add',
	    component:PCAdd,
	},
	{
	    path:'/social',
	    component:PCSocial
	},
	{
	    path:'/school',
	    component:PCSchool
	},
	{
	    path:'/socialjob/:id',
	    component:PCSocialJob
	},
	{
	    path:'/socialdetail/:id',
	    component:PCSocialDetail 
	},
	{
	    path:'/article',
	    component:PCArticle 
	},
	{
	    path:'/forum',
	    component:PCForum 
	},
	{
	    path:'/resetting',
	    component:PCResetting,
	},
	{
	    path:'/Maillogin',
	    component:PCMaillogin,
	},
	{
	    path:'/Mailaccount',
	    component:PCMailaccount,
	},
	{
	    path:'/Mailsignup',
	    component:PCMailsignup,
	},
	{
	    path:'/Mailpassword',
	    component:PCMailpassword,
	},
	{
	    path:'/Articlelist',
	    component:PCArticlelist,
	},
]
const routesM = [
	{
	    name:'home',
	    path:'/',
	    redirect:'/Home',
	    component:MHome
	},
	{
	    path:'/home',
	    component:MHome
	},
	{
	    path:'/about',
	    component:MAbout
	},
	{
	    path:'/products',
	    component:MProducts
	},
	{
	    path:'/add',
	    component:MAdd,
	},
	{
	    path:'/social',
	    component:MSocial
	},
	{
	    path:'/school',
	    component:MSchool
	},
	{
	    path:'/socialjob/:id',
	    component:MSocialJob
	},
	{
	    path:'/socialdetail/:id',
	    component:MSocialDetail 
	},
	{
	    path:'/article/:id',
	    component:MArticle 
	},
	{
	    path:'/forum',
	    component:MForum 
	},
	{
	    path:'/resetting',
	    component:MResetting,
	},
	{
	    path:'/Maillogin',
	    component:MMaillogin,
	},
	{
	    path:'/Mailaccount',
	    component:MMailaccount,
	},
	{
	    path:'/Mailsignup',
	    component:MMailsignup,
	},
	{
	    path:'/Mailpassword',
	    component:MMailpassword,
	},
	{
	    path:'/Articlelist',
	    component:MArticlelist,
	},
]
var routes

if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    routes = routesM
  }else{
	routes = routesPC
  }

const router = new VueRouter({
    routes
		
    
})



export default router