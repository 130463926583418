<template>
  <div class="container">
    <Top :navArr="navArr"></Top>
    <!-- 主图 -->
    <div class="mainPic">
      <!-- 视频 -->
      <video-player
		:show-close-button="true"
        v-if="isplay"
        class="video-player vjs-custom-skin"
        :options="playerOptions"
		autoplay="autoplay"
		controls="controls"
      ></video-player>
      <!-- 遮罩 -->
      <div class="mask" v-show="isplay" @click="closeVideo"></div>
      <!-- 轮播 -->
      <div class="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="banner in BannerList"
            :key="banner.id"
            v-show="banner.show == 1"
          >
            <!-- 文字/图片动画效果 -->
            <div class="animate" v-if="banner.title">
              <div class="ani" swiper-animate-effect="fadeInDown">
                <img :src="`${baseURL}${banner.icon}`" />
                <h3 class="title">{{ banner.title }}</h3>
                <div v-html="banner.des" class="des"></div>
              </div>
            </div>

            <img :src="`${baseURL}${banner.src}`" />
            <!-- 播放按钮 -->
            <div class="playIcon" v-show="banner.video == 1">
              <img
                src="../../../assets/play.png"
                @click="Play(banner.video_src)"
                v-show="!isplay"
              />
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <!-- 热门产品 -->
    <div style="text-align: center" @click="closeVideo">
      <div class="hotgame">
        <div class="title">
          <h3>Popular Games</h3>
          <!-- <router-link to="/products"> More</router-link> -->
        </div>
        <ul>
          <li
            @mouseenter="handleEnterMouse(produces.id)"
            @mouseleave="handleLeaveMouse(produces.id)"
            v-for="produces in ProductsList"
            :key="produces.id"
            v-show="produces.show == 1"
          >
			<a :href="produces.link">
            <img :src="`${baseURL}${produces.preview}`" class="mainPic" />
			
            <!-- li里面的遮罩 -->
            <div
              v-show="liIndex == produces.id"
              class="animate__animated animate__fadeInDown li_mask"
              @click="toDetailPage"
            >
              <img style="width: 220px;height: 220px;" :src="`${baseURL}${produces.mask_icon}`" />
              <div style="color: aliceblue; font-size: 18px">
                {{ produces.title }}
              </div>
              <div style="color: aliceblue">
                {{ produces.mask_summary }}
              </div>
            </div>
			</a>
          </li>
        </ul>
      </div>
    </div>

    <!-- 最新动态 -->
    <div class="news" @click="closeVideo">
      <div class="title">
        <h3>News</h3>
      </div>
	  <div class="swiper2">
	    <div class="swiper-wrapper">
			<div class="swiper-slide"  v-for="(item,index) in news_list">
				<ul>
				  <li v-for="(itemChild,indexChild) in item">
				    <div class="link" @click="toArticle(itemChild.id)">
				      <div class="info">
				        <div class="Picbox">
				          <img :src="`${baseURL}${itemChild.preview}`" />
				        </div>
				        <div class="detail">
				          <span>{{ itemChild.title }}</span>
				          <span class="time">{{ itemChild.created_at }}</span>
				          <span class="summary">{{ itemChild.summary }}</span>
				        </div>
				      </div>
				    </div>
				  </li>
				</ul>
			</div>
	    </div>
	    <div class="swiper-pagination"></div>
	  </div>
      
    </div>
	
    <Bottom></Bottom>
  </div>
</template>

<script>
import * as swiperAni from "@/animate";
import "../../../../public/animate.min.css";
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper/bundle";
import { videoPlayer } from "vue-video-player";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { mapState } from "vuex";
import $ from 'jquery';


export default {
  name: "Home",
  components: { Top, Bottom, videoPlayer},
  data() {
    return {
	  news_list:{},
	  sparklingfishstudioUrl:'https://sparklingfishstudio.com',
      swiper: {},
      isplay: false, //是否播放
      liIndex: -1, //标记触发哪个li标签
      page: 1,
      navArr: [
        {
          name: "HOME",
          path: "/home",
        },
      ],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度

        autoplay: true, //如果true,浏览器准备好时开始回放。

        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）

        language: "zh-CN",

        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）

        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

        sources: [
          {
            type: "video/mp4",

            src: "", //视频url地址
          },
        ],

        notSupportedMessage: "	", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      },
    };
  },
  mounted() {
	  
    // window.Play = (e) => {
    //   this.Play(e);
    // };
    this.$store.commit("BANNERLIST"); //拿banner数据
    this.$store.commit("PRODUCTSLIST"); //热门产品
    this.$store.commit("NEWSLIST"); //最新动态
	
	//console.log(event.target.innerHTML.replace(/[^\u4e00-\u9fa5]/gi,""))
	  document.getElementsByTagName('html')[0].style.width = document.documentElement.clientWidth+'px';
	  document.getElementsByTagName('html')[0].style['min -height'] = document.documentElement.clientHeight+'px';
	  document.getElementsByTagName('html')[0].style['min-height'] = document.documentElement.clientHeight+'px';
	  document.getElementsByClassName('container')[0].style['min-height'] = document.documentElement.clientHeight+'px';
	  var news = [];
	  
	  var that = this
	  $.ajax({
	  	  method:'POST',
	  	  dataType: 'text',
	  	  data:{type:'mail',bind:this.mail,password:this.password},
	  	  jsonpCallback: "jsonpcallback",
	  	  url: this.sparklingfishstudioUrl+'/api/article',
	  	  success: function (res) {
	  		  res =  JSON.parse(res)
	  		  if(res.code == 0){
	  		  	var news = [];
	  		  	for(var i in res.data.data){
	  		  			  if(!news[Math.floor(i/4)])news[Math.floor(i/4)] = [];
	  		  			  news[Math.floor(i/4)].push(res.data.data[i])
						  console.log(news)
						  that.$set(that.news_list,Math.floor(i/4),news[Math.floor(i/4)]);
	  		  	}
	  		  	
				console.log(that.news_list)
				var mySwiper = new Swiper('.swiper2',{
					 pagination: {
					     el: '.swiper-pagination',
						 clickable: true,
						 renderBullet: function (index, className) {
						   return '<span class="' + className + '">' + (index + 1) + "</span>";
						 },
					   },
					navigation: {
					  nextEl: ".swiper-button-next_btn",
					  prevEl: ".swiper-button-prev_btn",
					},
				  // effect : 'coverflow',
				  slidesPerView: 1,
				  centeredSlides: true,
				  width:1300,
				  
				})
				
				    // var swiper = new Swiper(".mySwiper", {
				    //   pagination: {
				    //     el: ".swiper-pagination",
				    //     clickable: true,
				    //     renderBullet: function (index, className) {
				    //       return '<span class="' + className + '">' + (index + 1) + "</span>";
				    //     },
				    //   },
				    // });
				  
	  		  }
	  	  }
	  })
	 
  },
  methods: {
    Play(src) {
      // var src = e.getAttribute("data-src");
      //console.log("点了播放");
      this.isplay = !this.isplay;
      this.playerOptions.sources[0].src = `${this.baseURL}${src}`;
      this.swiper.autoplay.stop();
    },
    closeVideo() {
      console.log("关闭视频");
      this.isplay = false;

      this.swiper.autoplay.start();
    },
    handleEnterMouse(id) {
      //console.log("鼠标进入", id);
      this.liIndex = id;
    },
    handleLeaveMouse(id) {
      //console.log("鼠标离开", id);
      this.liIndex = -1;
    },
    //最新动态详情
    toArticle(id) {
      this.$router.push({path:'/article',query: {id:id}})
      // this.$store.dispatch("NewsDetailList", id);
    },
    //跳转产品详情页
    toDetailPage() {
      // this.$router.push({path:'/products'})
    },
  },
  computed: {
    ...mapState({
      baseURL: (state) => state.baseURL,
      BannerList: (state) => state.BannerList,
      ProductsList: (state) => state.ProductsList,
      NewsList: (state) => state.NewsList,
    }),
  },
  watch: {
    BannerList() {
      this.$nextTick(() => {
        this.swiper = new Swiper(".swiper", {
          autoplay: {
            disableOnInteraction: false,
          },
          loop: true,
          simulateTouch: false, //禁止鼠标模拟
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          on: {
            init: function () {
              swiperAni.swiperAnimateCache(this); //隐藏动画元素
              swiperAni.swiperAnimate(this); //初始化完成开始动画
            },
            slideChangeTransitionEnd: function () {
              swiperAni.swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
              //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
              this.slideToLoop(this.realIndex, 0, true);
         
            },
          },
        });
      });
    },
  },
};
</script>

<style scoped>
.container {
  background-image: url("@/assets/GalacticSpark_bg.png");
  background-size: auto 100%;
}
.mainPic {
  position: relative;
  overflow-x: hidden;
}
.swiper {
  width: 100%;
  height: 720px;
  margin: 0;
  padding: 0;
  position: relative;
}
.swiper2 {
	overflow: hidden;
  position: relative;
  height: 25rem;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
>>> .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  color:#000;
  background: rgba(0, 0, 0, 0.2);
}
>>> .swiper-pagination-bullet-active {
  background-color: #ff6600;
}
.swiper-button-next,
.swiper-button-prev {
  color: #ff6600;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  width: 35px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
}
.animate {
  position: absolute;
  z-index: 2;
  top: 200px;
  left: 300px;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000;
  color: white;
}
.animate img {
  width: 80px;
  height: 80px;
}
.animate .title {
  display: inline-block;
  height: 40px;
  line-height: 40px;
}
.animate .des {
  border-top: 1px solid #fff;
  font-size: 13px;
  padding-top: 10px;
}
.playIcon {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.playIcon img {
  width: 200px;
  height: 200px;
  cursor: pointer;
}

.video-player {
  position: absolute;
  z-index: 10086;
  top: 1%;
  left: 50%;
  margin-left: -615px;
  /* transform: translate(-50%, -50%); */
  width: 1230px; 
  height: 500px;
}
.mask {
  width: 100%;
  height: 720px;
  position: absolute;
  z-index: 3;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.hotgame {
  display: inline-block;
  width: 1250px;
  /* background-color: rgb(236, 73, 73); */
  padding: 0 25px;
  box-sizing: border-box;
}
.hotgame .title {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hotgame ul {
  width: 1230px;
  min-height: 50px;
  overflow: hidden;
  max-height: 670px;
  /* padding: 0 10px; */
  /* margin: 0 auto; */
  /* padding:10px 50px 0; */
  /* background-color: rgb(16, 51, 148); */
}
.hotgame ul li {
  position: relative;
  /* width: 380px; */
  /* height:310px; */
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}
.hotgame ul li .mainPic {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.li_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.news {
  width: 1200px;
  height: 500px;
  /* background-color: rgb(34, 133, 172); */
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
}
.news .title {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.news ul {
  width: 1200px;
}
.news ul li {
  width: 580px;
  height: 160px;
  /* background-color: rgb(24, 196, 202); */
  float: left;
  margin-bottom: 15px;
  margin-right: 20px;
}
.news .link {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.link .info {
  /* display: flex; */
}
.link .detail {
  display: inline-block;
  font-size: 16px;
  flex-direction: column;
  margin-left: 15px;
  width: 250px;
}
.link .detail span{
	display: block;
}

.detail .time {
  font-size: 12px;
  color: grey;
}
.detail .summary {
  font-size: 14px;
  color: rgb(54, 54, 54);
}
.info .Picbox {
  display: inline-block;
  width: 300px;
  height: 160px;
  overflow: hidden;
  border-radius: 8px;
  vertical-align: top;
}
.link .info img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.link .info img:hover {
  transform: scale(1.3);
}
>>> .el-pager li {
  background: transparent;
}
>>> .el-pagination .btn-prev,
>>> .el-pagination .btn-next {
  background: center center no-repeat transparent;
}
/* >>>.el-pagination .btn-next .active {
        background-color: #ff7d00 !important;
}
t 
>>>.el-pagination .btn-next,
>>>.el-pagination .btn-prev{
  width: 12px;
  height: 12px;
  min-width: 12px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: rgb(189, 188, 188);
}
>>>.el-pagination button{
  padding: 0;
}
>>>.el-icon-arrow-right:before,
>>>.el-icon-arrow-left:before{
  content:'1';
  color:transparent;
  border-radius: 50%;
  background-color: red;
} */

.pageBox input {
  margin-right: 8px;
}
</style>
