<template>
  <div class="topContainer">
    <div class="top">
    <div class="logo-box">
      <router-link to="/home">
        <img src="../assets/GalacticSpark_logo.png" class="logo" />
      </router-link>
    </div>
    <ul class="nav">
      <li v-for="(nav, index) in navArr" :key="index"  >
        <div @click="toPage(nav.path)" :class="{ active: $route.path===nav.path}" class="link">{{ nav.name }}</div>
      </li>
    </ul>
	
	<button v-if="token == null" class="loginBtn" @click="showLogin()">login</button>
	<div v-if="token"  class="account_box" >
		<img class="account" src="../assets/user.png"  @click="onShowTab()"/>
		<ul class="account_tab" v-show="account_tab">
			<li>{{mail}}</li>
			<li @click="toMailAccount()">account info</li>
			<li @click="onLoginout()">logout</li>
		</ul>
	</div>
	
	<Maillogin v-show="loginmodel"></Maillogin>
	<Mailsignup v-show="signupmodel"></Mailsignup>
	<Mailpassword v-show="passwordmodel"></Mailpassword>
  </div>
  </div>
</template>


<script>
	
import Maillogin from "@/views/pc/pages/Maillogin.vue";
import Mailsignup from "@/views/pc/pages/Mailsignup.vue";
import Mailpassword from "@/views/pc/pages/Mailpassword.vue";
import $ from 'jquery';
	
export default {
  name: "Top",
  components: { Maillogin ,Mailsignup ,Mailpassword},
  props:["navArr"],
  data() {
    return {
		apiUrl:'https://p03vcenter.wenhaogame.com',
      // navArr: ["首页", "游戏产品", "关于问皓", "官方论坛","加入问皓", "联系我们"],
	  token:'',
	  loginmodel:false,
	  signupmodel:false,
	  passwordmodel:false,
	  account_tab:false,
	  mail:'',
    };
  },
   mounted() {
	   this.token = localStorage.getItem('token');
	   this.mail = localStorage.getItem('mail');
	   if(this.token){
		   this.getAccountInfo();
	   }
   },
  methods: {
	  
    toPage(path,index) {
      console.log("传入相应的路径",index,path);
	  path = path + '?d=' + Math.random();
      this.$router.push({path})
    },
	
	//弹出登录弹窗
	showLogin(){
		console.log(localStorage.getItem('token'))
		this.loginmodel = !this.loginmodel
		this.signupmodel = false;
		this.passwordmodel = false;
	},
	//弹出注册弹窗
	showSignup(){
		this.signupmodel = !this.signupmodel
		this.loginmodel = false;
		this.passwordmodel = false;
	},
	//弹出重置密码
	showPassword(){
		this.passwordmodel = !this.passwordmodel
		this.loginmodel = false;
		this.signupmodel = false;
	},
	logined(){
		this.token = localStorage.getItem('token');
		this.signupmodel = false;
		this.loginmodel = false;
		this.passwordmodel = false; 
	},
	onLoginout(){
		localStorage.removeItem("token");
		this.token = null;
		this.$router.push({path:'/home'})
	},
	onShowTab(){
		this.account_tab = !this.account_tab
	},
	toMailAccount(){
		this.$router.push({path:'/mailaccount'})
	},
	getAccountInfo(){
		$.ajax({
		  method:'POST',
		  dataType: 'text',
		  data:{token:this.token},
		  jsonpCallback: "jsonpcallback",
		  url: this.apiUrl+'/account/account_info',
		  success: function (res) {
				  res =  JSON.parse(res)
			  if(res.code == 0){
				this.mail = res.data.mail
			  }else{
				alert(res.msg);
			  }
		  }
		})
	}
	
  },
};
</script>

<style scoped>
.topContainer{
    height: 100px;
	text-align: center;
}
.top {
  width: 100%;
  height: 100px;
  background-color: rgba(31, 30, 30, 0.9);
  z-index: 99999;
  position: fixed;
  color: aliceblue
}
.top .logo-box {
  float: left;
}
.top .logo-box .logo {
  width: 130px;
  height: 100px;
  margin-left: 300px;
}
.nav {
  height: 100px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 5%;
  width: 60%;
  text-align: left;
  padding-left: 5rem;
}
.nav li {
  align-items: center; 
  font-size: 16px;
  margin-right: 50px;
  height: 98px;
  width: 10%;
}
.nav li .link{
  height: 98px;
  line-height: 98px;
}
.nav li:hover{
    cursor: pointer;
    font-size: 18px;
    color: rgb(219, 109, 6);
    border-bottom: 4px solid rgb(219, 109, 6);
}
.active{
  color: rgb(219, 109, 6);
}
.loginBtn{
	position: absolute;
	top: 2rem;
	right: 0;
	width: 3%;
	height: 1.5rem;
	font-size: 16px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 5px;
	margin-right: 4%;
}
.account_box{
	position: absolute;
	top: 2rem;
	right: 0;
	width: 7%;
}
.account{
	display: inline-block;
	width: 25%;
	margin: 1.5%;
	padding: 0 0.5%;
	font-size: 16px;
	color: #fff;
	border: 0px;
	border-radius: 5px;
}

.account_tab{
	border: #fe9500 1px solid;
	width: 80%;
}
.account_tab li{
	width: 95%;
	height: 2rem;
	line-height: 2rem;
	background-color: #1f1f1e;
	padding-left: 0.3rem;
	border-bottom: 1px #c4c4c4 solid;
	overflow:hidden;
}
</style>
