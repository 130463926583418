<template>
    <h1>官方论坛</h1>
</template>

<script>
export default {
    name:'Forum'
}
</script>

<style>

</style>