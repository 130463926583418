<template>
	<div class="container" :style="{ height: containerHeight + 'px' }">
		<div class="content_box">
			<div class="sign_box">
				<div class="sing_type" v-show="role_id == ''">
					<div class="sign_in" @click="googleLogin()"><img src="../../../assets/google.png">sign in with google</div>
					<div class="sign_in" @click="facebookLogin()"><img src="../../../assets/facebook.png">sign in with facebook</div>
					<div class="sign_in" @click="twitterLogin()"><img src="../../../assets/twitter.png">sign in with twitter</div>
				</div>
				<div class="role_id" v-if="role_id !=''">
					{{tokenText()}}
				</div>
			</div>
			<div class="guide">
				<input id="che" type="checkbox" v-model:value="ihave_val">
				<label for="che">You have read <a href="#" @click="showGuide()"> &lt;Account Cancellation Guide&gt; </br></a>and confirmed the cancellation</label>
			</div>
			<div>
				<button class="submit" @click="onSubmit()" v-if="canSubmit">Confirm</button>
			</div>
		</div>
		<div class="guide_box" :style="{ display : guideIsShow }">
			<div class="mask"></div>
			<div class="guide_content">
				<div>
					<h2>Account Cancellation Guide</h2>
					<p>My Lord, we are sorry for your cancellation request, after the successful cancellation, the account will not be able to log in the game products operated by Sparkling Fish Studio and the account can not be recovered, and we will delete the information of game character under the account. When submitting your cancellation request, please make sure that you have carefully read and confirmed that the following conditions:</p>
					<p class="paragraph"><span>1.</span>You confirm that the account is in a secure state;</p>
					<p class="paragraph"><span>2.</span>You confirm that the benefits of the game have been settled or properly dealt with, including but not limited to diamonds, monthly cards, gift packs and other benefits, if not, deemed that you give up the benefits voluntarily;</p>
					<p class="paragraph"><span>3.</span>After the successful cancellation, Sparkling Fish Studio reserves the right not to provide you with any further services</p>
					<p class="paragraph"><span>4.</span>Any inconvenience or adverse consequences caused by canceling your account will be at your own risk, independent of Sparkling Fish Studio.</p>
				</div>
				<div style="margin-top: 1rem;">
					<input id="ihave" type="checkbox" v-model:value="ihave_val">
					<label for="ihave" style="color: blue;">I have read and agreed to the cancellation conditions</label>
				</div>
				<div >
					<button style="margin: 1rem;" @click="close_guide()">Confirm</button>
					<button style="margin: 1rem;" @click="hide_guide()">Cancel</button>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
import * as swiperAni from "@/animate";
import "../../../../public/animate.min.css";
import Swiper from "swiper/bundle";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { mapState } from "vuex";
import $ from 'jquery';
import '../../../assets/font/font.css'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAGGFtbh4aCvfl9KdqohcpEIKmoCDpvzyE",
  authDomain: "monster-miner-4a888.firebaseapp.com",
  projectId: "monster-miner-4a888",
  storageBucket: "monster-miner-4a888.appspot.com",
  messagingSenderId: "1070925057370",
  appId: "1:1070925057370:web:a8a8b11a73b9f85418f763",
  measurementId: "G-KW5H50TBTV"
};

// // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import { getAuth, signInWithEmailAndPassword, getRedirectResult, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from "firebase/auth";


const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const auth = getAuth();


export default {
	
  name: "Resetting",
  components: { Top, Bottom },
  data() {
    return {
      swiper: {},
      page: 1,
      navArr: [
        {
          name: "HOME",
          path: "/home",
        },
      ],
	  containerHeight:0,
	  guideIsShow:'none',
	  ihave_val:0,
	  token:'',
	  server_id:1,
	  apiUrl:'https://p03vcenter.wenhaogame.com',
	  role_id:'',
	  canSubmit:true,
	};
  },
  mounted(){
	  this.containerHeight =  document.documentElement.clientHeight;
  },
  methods: {
	  googleLogin:function(){
	  		  signInWithPopup(auth, googleProvider)
	  		    .then((result) => {
	  		      // This gives you a Google Access Token. You can use it to access the Google API.
	  		      const credential = GoogleAuthProvider.credentialFromResult(result);
	  		      const token = credential.accessToken;
	  		      // The signed-in user info.
	  		      const user = result.user;
	  		      // IdP data available using getAdditionalUserInfo(result)
	  		      // ...
				  console.log(user)
				  this.token = user.uid
				  this.getAccountInfo()
	  		    }).catch((error) => {
	  		      // Handle Errors here.
	  		      const errorCode = error.code;
	  		      const errorMessage = error.message;
	  		      // The email of the user's account used.
	  		      const email = error.customData.email;
	  		      // The AuthCredential type that was used.
	  		      const credential = GoogleAuthProvider.credentialFromError(error);
	  		      // ...
				  console.log(credential)
	  		    });

	  },
	  facebookLogin:function(){
	  		  signInWithPopup(auth, facebookProvider)
	  		    .then((result) => {
	  		      // The signed-in user info.
	  		      const user = result.user;
	  		  
	  		      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
	  		      const credential = FacebookAuthProvider.credentialFromResult(result);
	  		      const accessToken = credential.accessToken;
	  		  
	  		      // IdP data available using getAdditionalUserInfo(result)
	  		      // ...
				  console.log(user)
				  this.token = user.uid
				  this.getAccountInfo()
	  		    })
	  		    .catch((error) => {
	  		      // Handle Errors here.
	  		      const errorCode = error.code;
	  		      const errorMessage = error.message;
	  		      // The email of the user's account used.
	  		      const email = error.customData.email;
	  		      // The AuthCredential type that was used.
	  		      const credential = FacebookAuthProvider.credentialFromError(error);
	  		  
	  		      // ...
				   console.log(credential)
				});
	  },
	  twitterLogin:function(){
	  		  signInWithPopup(auth, twitterProvider)
	  		    .then((result) => {
	  		      // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
	  		      // You can use these server side with your app's credentials to access the Twitter API.
	  		      const credential = TwitterAuthProvider.credentialFromResult(result);
	  		      const token = credential.accessToken;
	  		      const secret = credential.secret;
	  		  
	  		      // The signed-in user info.
	  		      const user = result.user;
	  		      // IdP data available using getAdditionalUserInfo(result)
	  		      // ...
				  this.token = user.uid
				  this.getAccountInfo()
	  		    }).catch((error) => {
	  		      // Handle Errors here.
	  		      const errorCode = error.code;
	  		      const errorMessage = error.message;
	  		      // The email of the user's account used.
	  		      const email = error.customData.email;
	  		      // The AuthCredential type that was used.
	  		      const credential = TwitterAuthProvider.credentialFromError(error);
	  		      // ...
	  		    });
	  },
	  showGuide:function(){
		   this.guideIsShow = 'block'
	  },
	  close_guide:function(){
		  this.guideIsShow = 'none'
	  },
	  hide_guide:function(){
		  this.guideIsShow = 'none'
	  },
	  onSubmit:function(){ 
		  if(this.ihave_val == 0){
				alert('Confirm <Account Cancellation Guide> ');
				return false;
		  }
		  if(this.token == ''){
				alert('Login');
				return false;
		  }
		  if(confirm('Account Cancellation')==true){
			  
				this.ResettionAccount();
		  }
		  
		  
	  },
	  getAccountInfo:function(){
		  var that = this
		  $.ajax({
			  method:'POST',
			  dataType: 'text',
			  data:{token:this.token,server_id:this.server_id},
			  jsonpCallback: "jsonpcallback",
			  url: this.apiUrl+'/api/account_info',
			  success: function (res) {
				  if(res.data.code == 0){
					that.token = res.data.data.data.token
					that.role_id = res.data.data.data.role_id
				  }else{
					alert('account undefined');
				  }
			  },
		  })
	  },
	  ResettionAccount:function(){
	  		  var that = this
	  		  $.ajax({
				  method:'POST',
				  dataType: 'text',
				  data:{token:this.token,server_id:this.server_id},
				  jsonpCallback: "jsonpcallback",
				  url: this.apiUrl+'/api/resetting',
				  success: function (res) {
					  if(res.code == 0){
						that.canSubmit = false
						alert(res.msg)
					  }else{
						alert('system error')
					  }
				  },
	  		  })
	  },
	  tokenText:function(){
		  var role_id = this.role_id
		  return 'role_id:'+role_id
	  }
  },
  computed: {
   
   
  },
  watch: {
    
  },
};
</script>

<style scoped>
	

.container {
  background-image: url("@/assets/GalacticSpark_bg.png");
  background-size: auto 100%;
  height: 100%;
  text-align: center;
}
.content_box{
	display: inline-block;
	padding-top: 15rem;
}

.sign_box{
	text-align: center;
	height: 5rem;
}
.sign_in{
	display: flex;
	text-align: center;
	width: 12rem;
	border: 1px solid #000;
	border-radius: 10px;
	margin: 1rem 10rem;
	padding: 1px 1rem;
}
.sign_in img{
	width: 20px;
	margin-right: 3px;
}
.guide{
	padding: 3rem 0px;
}
.submit{
	background: #4d85ff;
	width: 4rem;
	height: 2rem;
	border: 0px solid;
	border-radius: 5px;
	color: #fff;
}
.guide_box{
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
}
.guide_box .mask{
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.5;
}
.guide_content{
	width: 60%;
	position: absolute;
	top: 20%;
	left:20%;
	background-color: #fff;
	text-align: left;
	padding: 1rem;
	font-family: Peddana-Regular;
	font-size: 1.4rem;
}
.guide_content h2{
	font-size: 2rem;
}

.guide_content .paragraph{
	margin-left: 1em;
}
.guide_content .paragraph span{
	display: inline-block;
	width: 1rem;
	margin-left: -1rem;
}
</style>
