import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import "animate.css"; //引入css动画效果样式
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store';
Vue.use(ElementUI);
//video插件
import videoPlayer from 'vue-video-player';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css';
// import videoPlayer from '@videojs-player/vue'
// import 'video.js/dist/video-js.css'
Vue.use(videoPlayer)


import GSignInButton from 'vue-google-signin-button'
Vue.use(GSignInButton);


Vue.config.productionTip = false;
new Vue({
  render: h => h(App),
  router,
  store,
  token:'',
}).$mount('#app')
