<template>
    <div class="city-box">
    <div style="overflow: hidden">
      <div class="g-f-l">
        <select v-model="province" class="select-box">
          <!-- <option value="" selected="selected">广东省</option> -->
          <option v-for="item in provinceList" :value="item" :key="item.id" selected="selected">{{ item.name }}</option>
        </select>
      </div>
      <div class="g-f-l" style="margin-left: 10px;">
        <select v-model="city" class="select-box">
          <!-- <option value="" v-if="selectAddr" selected="selected">广州市</option> -->
          <option v-for="item in cityList" :value="item" :key="item.id" selected="selected">{{ item.name }}</option>
        </select>
      </div>
    </div>

    <!-- <a href="javascript:;" @click="submitCity()">提交</a> -->

  </div>
</template>

<script>
import { province, city } from '../vue-area'
  export default {
    name: 'Error',
   data() {
    return {
      province:'',
      city:'',
      provinceList:[],
      cityList:[],
      // selectAddr:true,
    }
  },
  mounted() {
    this.provinceList = [...province] /*取出省数据*/
     //挑选出广东省的地级市
     this.cityList = city.filter((item) => item.parentId === 440000)
    //设置默认广东省
    this.province = province.filter((item) => item.code === 440000)[0]
    //设置默认广州市
    this.city = city.filter((item) => item.code === 440100)[0]
  },
  watch: {
    province:function () {
      //省级改变时，地级市列表跟着变，并且地级默认设置为第一个地级市
      this.cityList = city.filter((item) => item.parentId === this.province.code)
      // this.city = city.filter((item) => item.parentId === this.province.code)[0]
    }
  },
  // methods: {
  //   submitCity() {
  //     if (this.province == ''){
  //       alert("请选择地址")
  //       return
  //     }
  //     if (this.city == '') {
  //       alert("请选择地址")
  //       return
  //     }
  //     /*提交到后台的数据*/
  //     alert(this.province.name+""+this.city.name)
  //   }
  // }
  }
</script>

<style scoped>
.g-f-l{
  float: left;
}
.city-box{
  /* width: 1200px; */
  /* margin: 0 auto; */
  /* margin-top: 200px; */
}
.select-box{
  width: 100px;
  height: 32px;
  outline: none;
  border: none;
  font-size: 16px;
  /* appearance:none; */
  /* color:rgb(48, 47, 47); */
}
</style>
