<template>
  <div class="topContainer">
    <div class="top topb" >
    <div class="logo-box">
      <router-link to="/home">	
		<img src="../assets/mobile/titleb.png" class="logo titleb" />
      </router-link>
    </div>
	<div class="tab-box" @click="tabToggle()">
	  <router-link to="">
	    <img src="../assets/mobile/tabb.png" class="tab" />
	  </router-link>
	</div>
    <ul class="nav"  v-show="tabShow">
      <li v-for="(nav, index) in navArr" :key="index"  >
        <div @click="toPage(nav.path)" :class="{ active: $route.path===nav.path}" class="link">{{ nav.name }}</div>
      </li>
	 <li v-if="token == null">
	 		  <div @click="showLogin()">login</div>
	 </li>
	 <li v-if="token">
	 		  <div @click="toMailAccount()">account info</div>
	 </li>
	 <li v-if="token">
	 		  <div @click="onLoginout()">Logout</div>
	 </li>
    </ul>
	
	
	
	<Maillogin v-show="loginmodel"></Maillogin>
	<Mailsignup v-show="signupmodel"></Mailsignup>
	<Mailpassword v-show="passwordmodel"></Mailpassword>
  </div>
  </div>
</template>


<script>
	
	import Maillogin from "@/views/mobile/pages/Maillogin.vue";
	import Mailsignup from "@/views/mobile/pages/Mailsignup.vue";
	import Mailpassword from "@/views/mobile/pages/Mailpassword.vue";
import $ from 'jquery';
	
export default {
  name: "MobileTopCp",
  components: { Maillogin ,Mailsignup ,Mailpassword},
  props:["navArr"],
  data() {
    return {
		apiUrl:'https://p03vcenter.wenhaogame.com',
      // navArr: ["首页", "游戏产品", "关于问皓", "官方论坛","加入问皓", "联系我们"],
	  token:'',
	  loginmodel:false,
	  signupmodel:false,
	  passwordmodel:false,
	  account_tab:false,
	  mail:'',
	  tabShow:false,
    };
  },
   mounted() {
	   this.token = localStorage.getItem('token');
	   this.mail = localStorage.getItem('mail');
	   if(this.token){
		   this.getAccountInfo();
	   }
   },
  methods: {
	  
    toPage(path,index) {
      console.log("传入相应的路径",index,path);
	  path = path + '?d=' + Math.random();
      this.$router.push({path})
    },
	
	//弹出登录弹窗
	showLogin(){
		console.log(localStorage.getItem('token'))
		this.loginmodel = !this.loginmodel
		this.signupmodel = false;
		this.passwordmodel = false;
	},
	//弹出注册弹窗
	showSignup(){
		this.signupmodel = !this.signupmodel
		this.loginmodel = false;
		this.passwordmodel = false;
	},
	//弹出重置密码
	showPassword(){
		this.passwordmodel = !this.passwordmodel
		this.loginmodel = false;
		this.signupmodel = false;
	},
	logined(){
		this.token = localStorage.getItem('token');
		this.signupmodel = false;
		this.loginmodel = false;
		this.passwordmodel = false; 
	},
	onLoginout(){
		localStorage.removeItem("token");
		this.token = null;
		this.$router.push({path:'/'})
	},
	onShowTab(){
		this.account_tab = !this.account_tab
	},
	toMailAccount(){
		this.$router.push({path:'/mailaccount'})
	},
	getAccountInfo(){
		$.ajax({
		  method:'POST',
		  dataType: 'text',
		  data:{token:this.token},
		  jsonpCallback: "jsonpcallback",
		  url: this.apiUrl+'/account/account_info',
		  success: function (res) {
				  res =  JSON.parse(res)
			  if(res.code == 0){
				this.mail = res.data.mail
			  }else{
				alert(res.msg);
			  }
		  }
		})
	},
	tabToggle(){
		this.tabShow = !this.tabShow
	}
  },
};
</script>

<style scoped>
.topContainer{
    /* height: 100px; */
	text-align: center;
	position: relative;
}
.top {
  width: 100%;
  height: 3.5rem;
  z-index: 99999;
  color: aliceblue;
  position: absolute;
}
.topb{
	background-color: #fff;
}
.top .logo{
	width: 10rem;
	padding: 1rem;
}
.top .tab-box {
  position: absolute;
  top: 0;
  right: 0rem;
  padding: 1rem;
}
.top .tab-box .tab {
	width: 1.7rem;
}
.nav {
  width: 10rem;
  position: absolute;
  right: 0;
  top: 3.5rem;
  background-color: #fff;
}
.nav li {
  align-items: center; 
  font-size: 16px;
  border-bottom: #e5e5e5 1px solid;
}

.nav li div{
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  color: #000;
}
.nav li .link{
	color: #000;
  text-align: center;
}
/* .nav li:hover{
    cursor: pointer;
    font-size: 18px;
    color: rgb(219, 109, 6);
    border-bottom: 4px solid rgb(219, 109, 6);
} */
.active{
  color: #22fdf9;
}
.loginBtn{
	position: absolute;
	top: 2rem;
	right: 0;
	width: 3%;
	height: 1.5rem;
	font-size: 16px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 5px;
	margin-right: 4%;
}
.account_box{
	position: absolute;
	top: 2rem;
	right: 0;
	width: 7%;
}
.account{
	display: inline-block;
	width: 25%;
	margin: 1.5%;
	padding: 0 0.5%;
	font-size: 16px;
	color: #fff;
	border: 0px;
	border-radius: 5px;
}

.account_tab{
	border: #fe9500 1px solid;
	width: 80%;
}
.account_tab li{
	width: 95%;
	height: 2rem;
	line-height: 2rem;
	background-color: #1f1f1e;
	padding-left: 0.3rem;
	border-bottom: 1px #c4c4c4 solid;
	overflow:hidden;
}
</style>
