<template>
	<div class="container" id="pmailContainer" @touchmove.prevent="touchmove">
		<div class="popacity_mask" @touchstart.prevent="onClose"></div>
		<div class=" mail-login" >
			<div class="main">
				<div class="mail-logo">
					<img src="../../../assets/GalacticSpark_logo.png" class="login-logo" />
				</div>
				<div class="input-box">
					<input type="text" name="mail" class="mail" placeholder="Email" v-model="mail"> 
					<button v-show="btnLoad == false" class="send_code" @click="sendCode()">Send</button>
					
					<div v-show="btnLoad == true" class="load"><span id="loading-icon"></span></div>
				</div>
				<div class="input-box">
					<input type="text" name="code"  placeholder="Code" v-model="code">
				</div>
				<div class="input-box">
					<input type="password"  name="password"   placeholder="Password" v-model="password">
				</div>
				<div class="input-box">
					<input type="password"  name="re_password"   placeholder="Confirm Password" v-model="re_password">
				</div>
				
				<div class="box2">
					<div class="remember">
					<input type="checkbox" name="remember_me"  v-model="remember_me">
					<label for="remember_me">Remember me</label>
					</div>
				</div>
				<div><button class="login" @click="onChangePassword()">Change</button></div>
				<div class="box4">
					<p>Don't have a account? <a href="#" id="signupBtn" @click="showSignup()">Sign up now!</a></p>
					<p>Have a account? <a href="#" @click="showLogin()">Login!</a></p>
				</div>
			</div>
		</div>
	</div>
	
	
	
	
</template>

<script>
import * as swiperAni from "@/animate";
import "../../../../public/animate.min.css";
import Swiper from "swiper/bundle";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import { mapState } from "vuex";
import $ from 'jquery';



export default {
	
  name: "Maillogin",
  components: { Top, Bottom },
  loginShow:true,
  data() {
    return {
	  apiUrl:'https://p03vcenter.wenhaogame.com',
      swiper: {},
      page: 1,
      navArr: [
        {
          name: "HOME",
          path: "/home",
        },
      ],
		containerHeight:1,
		mail:'',
		code:'',
		password:'',
		re_password:'',
		remember_me:true,
		btnLoad:false,
	};
  },
  mounted(){
	  this.containerHeight =  document.documentElement.clientHeight;
	  
	  var img = new Image();
	  img.src = "https://www.sparklingfishstudio.com/static/admin/images/bg-screen2.jpg?v="+Date.now();
	  img.onload = function(){
		  if(document.getElementById('lmailContainer').style.height)document.getElementById('pmailContainer').style.height = document.body.scrollHeight + 'px'
		 document.getElementsByClassName('popacity_mask')[0].style.height = document.body.scrollHeight + 'px'
		 document.getElementsByClassName('popacity_mask')[0].style.width = document.body.scrollWidth + 'px'
		  
	  }
  },
  methods: {
	  sendCode(){
		  this.btnLoad = true;
		  $.ajax({
			  method:'POST',
			  dataType: 'text',
			  data:{mail:this.mail},
			  jsonpCallback: "jsonpcallback",
			  url: this.apiUrl+'/account/send_change_password_code',
			  success: function (res) {
				  res =  JSON.parse(res)
				 
				  if(res.code == 0){
					alert('success');
				  }else{
					alert('error');
				  }
				  this.btnLoad = false;
			  }
		  })
	  },
	  onChangePassword(){
	  	if(!this.code){
	  		alert('code is null');return false; 
	  	}
	  	if(this.remember_me){
			localStorage.setItem('mail',this.mail);	  
			localStorage.setItem('password',this.password);	    
	  	}else{
	  		localStorage.removeItem("mail");
	  		localStorage.removeItem("password");
	  	}
	  	
		$.ajax({
			  method:'POST',
			  dataType: 'text',
			  data:{mail:this.mail,code:this.code,password:this.password,re_password:this.re_password},
			  jsonpCallback: "jsonpcallback",
			  url: this.apiUrl+'/account/change_password',
			  success: function (res) {
				  res =  JSON.parse(res)
				 
				  if(res.code == 0){
				  	alert('success');
				  	localStorage.removeItem("token");
				  	this.$router.push({path:'/'})
				  }else{
				  	alert('error');
				  }
			  }
		})
	  },
	  showLogin(){
	  	this.$parent.showLogin()
	  },
	  showPassword(){
	  	this.$parent.showPassword()
	  },
	  showSignup(){
		  this.$parent.showSignup()
	  },
	  touchstart(e){
	  },
	  touchmove(e){
	  },
	  touchend(e){
	  },
	  onClose(){
		   this.$parent.showPassword()
	  },
  },
  computed: {
   
   
  },
  watch: {
    
  },
};
</script>

<style scoped>

.popacity_mask{
	position: absolute;
	background: #000;
	opacity: 0.5;
}
.mail-login{
	position: relative;
	top:5rem;
	left:50%;
	margin-left: -12rem;
	width: 20rem;
	padding: 2rem;
	background-color: #fff;
	color: #989898;
	padding-top: 5rem;
}


.main{
	position: relative;
	text-align: center;
}

.login-logo{
	position: absolute;
	top: 0px;
	left: 50%;
	width: 10rem;
	margin-left: -5rem;
	margin-top: -5rem;
}
.input-box{
	display: inline-block;
	padding: 0.5rem 10%;
	width: 80%;
	
}

.input-box input{
	float: right;
	width: 98%;
	height: 2rem;
	padding-left: 2%;
}
.box2{
	display: inline-block;
	padding: 0 10%;
	width: 80%;
	font-size: 12px;
}
.box2 .remember{
	float: left;
}
.box2 .forgot{
	float: right;
	color: #fe9500;
}
.login{
	width: 80%;
	height: 2.5rem;
	margin: 3%;
	font-size: 22px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 5px;
}
.box4{
	font-size: 12px;
}
.box4 p{
	margin-top: 0.3rem;
}
.box4 a{
	color: #fe9500;
}
.main .input-box .mail{
	width: 60%;
	float: left;
	vertical-align: top;
}
.send_code{
	width: 36%;
	height: 2.25rem;
	font-size: 22px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 2px;
}
.load{
	display: inline-block;
	width: 36%;
	height: 2.05rem;
	font-size: 22px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 2px;
	padding: 0.1rem 0;
}
#loading-icon {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 50%;
  border-top-color: #333;
  animation: spin 1s linear infinite;
}
 
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
