<template>
  <div style="width: 100%">
    <Top :navArr="navArr"></Top>
    <img src="../../../assets/WH.png" class="poster" />
    <div class="detail">
      <!-- 公司简介 -->
      <div class="info">
        <h1 class="title">公司简介</h1>
        <div class="com">
          <div class="content">
            <div class="word">
              <span class="space"></span
              >广州问皓软件科技有限公司成立于2014年，是一家专注于精品化手游研发与全球发行一体公司。公司自成立以来，一直秉持“打造面向用户的游戏精品、立足用户需求”的产品理念。
            </div>
            <div class="word">
              <span class="space"></span
              >经过短短数年努力，公司凭借深厚的研发和发行实力屡获殊荣 ，先后被评为“国家高新技术企业”、“广东省双软企业”、“广州市研发机构”、“广东省现代服务业500强企业”、“2021广州最具成长性文化企业20佳”等。
            </div>
          </div>
          <img src="../../../assets/componay.png" />
        </div>
        <div class="word">
          <span class="space"></span
          >公司及产品在行业内拥有较高的知名度，在与各大应用渠道深度合作过程中屡获大奖， 如 “博鳌论坛2021年度（行业）领军企业奖”、“金钻榜2021最具成长性企业20佳” 、“金钻榜2021年度优秀网络游戏企业奖”、“2021引擎奖年度新锐大奖”、“腾讯广告向阳奖2021年度品效杰出广告主”、“华为应用市场2021年度跃动星耀奖”、“2021年度优秀网络游戏企业”、“腾讯应用宝年度合作伙伴”、“OPPO开发者大会最具潜力合作伙伴”、“金茶奖最佳移动游戏发行商”、“硬核年度最受关注游戏公司”、“OPPO优秀合作伙伴”、等。其中直播引流主播数全行业TOP1、直播引流产品数游戏行业TOP1，是游戏行业首个入场抖音游戏直播营销，实现游戏主播规模化运营和全链路数据化验证。使用直播+短视频+竞价投放的组合模式，复合传播协同提效。同时实现规模化主播培训，精细化直播运营，引领游戏行业。
        </div>
        <div class="word">
          <span class="space"></span
          >问皓是一支年轻充满活力的队伍，我们拥有多年游戏研发和发行经验，具备精湛的科研技术和敏锐的行业洞察力，近几年不断打造出年流水数亿、月流持续破亿的优秀手游产品。旗下产品《九灵神域》《驯龙物语》《重生之明月传说》《猎魔传说》《暗黑觉醒》《仙灵奇缘》《昆仑墟》《刀剑物语》等精品游戏均受到业内及市场的广泛认可。在研发道路上不断搭建研发流程工业化、平台聚合化、资源共享化，制定高标准要求，致力于精品化手游创作。
        </div>
        <div class="word">
          <span class="space"></span
          >现阶段，公司正进行全面的战略布局，自研产品覆盖手游市场主流及有爆发潜力的项目类型，其中国漫二次元MMO、仙侠风MMO、次世代日韩魔幻MMO及首次探索的放置卡牌等新品大作将强势上线大推！发行的核心领域加快产品的引入进程，扩大业务规模，继续深化产品的全闭环市场运作。
        </div>
        <div class="word">
          <span class="space"></span
          >未来，问皓将继续深耕移动游戏领域，坚定全方位的发展战略，致力提升产品的品质和全球化线上运营能力，打造手机游戏精品大作，成就一流的移动游戏公司。
        </div>
      </div>
      <!-- 发展历程 -->
      <div class="dev">
        <h1 class="title">发展历程</h1>
        <div class="honor">
          <div class="sign">
            <h2 class="year">2021</h2>
            <span class="Fcircle">
              <span class="Ccircle"></span>
            </span>
          </div>

          <ul>
            <li>
              公司荣获2021
              OPPO开发者大会“最具潜力合作伙伴”奖、华为应用市场“2021年度跃动星耀”奖；
            </li>
            <li>
              公司荣获金钻榜“2021最具成长性企业”奖、“2021年度优秀网络游戏企业”奖、博鳌论坛“2021年度（行业）领军企业”大奖、“2021广州最具成长性文化企业20佳”奖项；
            </li>
            <li>
              公司荣获“2021引擎奖年度新锐大奖”、腾讯广告“向阳奖”2021年度品效杰出广告主奖；
            </li>
            <li>在深耕国内市场的同时逐步启动海外发行业务，打造全球业务链。</li>
          </ul>
        </div>
        <div class="honor">
          <div class="sign">
            <h2 class="year">2020</h2>
            <span class="Fcircle">
              <span class="Ccircle"></span>
            </span>
          </div>
          <ul>
            <li>自研产品《九灵神域》正式上线，全渠道大力推广，备受关注；</li>
            <li>
              公司荣获“金钻榜最具成长性企业”、“腾讯向阳奖2020年卓越贡献广告主”奖；
            </li>
            <li>CEO周皓黎荣获第六届硬核联盟黑石奖“硬核年度行业领军人物”奖。</li>
          </ul>
        </div>
        <div class="honor">
          <div class="sign">
            <h2 class="year">2019</h2>
            <span class="Fcircle">
              <span class="Ccircle"></span>
            </span>
          </div>
          <ul>
            <li>
              研发及发行团队成熟稳健，并不断开拓新的产品品类及业务领域，紧随市场趋势突破业务；
            </li>
            <li>
              自研产品《驯龙物语》正式上线，获得市场高度关注和热烈反响，取得优异的成绩，并荣获第五届硬核联盟黑石奖“硬核年度最受欢迎二次元游戏”奖；
            </li>
            <li>自研产品《暗黑觉醒》荣获三星应用商店2019“最具人气游戏” 奖；</li>
            <li>
              公司荣获三星应用商店“2019最佳合作伙伴”奖、第五届硬核联盟黑石奖“硬核年度最受关注游戏公司”奖、“金茶奖2019年最佳移动游戏发行商”奖；
            </li>
            <li>CEO周皓黎荣获第五届硬核联盟黑石奖“硬核年度行业领军人物”奖。</li>
          </ul>
        </div>
        <div class="honor">
          <div class="sign">
            <h2 class="year">2018</h2>
            <span class="Fcircle">
              <span class="Ccircle"></span>
            </span>
          </div>
          <ul>
            <li>
              公司自研团队持续发力，发行全闭环业务链逐步完善，业绩突飞猛进；
            </li>
            <li>
              自研产品《猎魔传说》再度荣获三星商店2018“最具影响力游戏” 奖；
            </li>
            <li>
              自研产品《重生之明月传说》和《暗黑觉醒》正式上线，成绩斐然；
            </li>
            <li>
              独代产品《宿命契约》荣获第四届硬核联盟黑石奖“硬核年度最受欢迎MMO游戏”
              奖，代理发行《刀剑物语》也取得优异的成绩；
            </li>
            <li>
              公司荣获“腾讯应用宝年度合作伙伴”奖、“OPPO优秀合作伙伴”奖、“金茶奖最佳移动游戏发行商”奖；
            </li>
            <li>
              CEO周皓黎荣获第四届硬核联盟黑石奖“硬核年度新锐影响力人物”奖。
            </li>
          </ul>
        </div>
        <div class="honor">
          <div class="sign">
            <h2 class="year">2017</h2>
            <span class="Fcircle">
              <span class="Ccircle"></span>
            </span>
          </div>
          <ul>
            <li>
              公司业务线全面布局加速，平台事业部成立，为产品发行保驾护航，团队规模和业绩成数倍速度增长，逐步完成创业期积累；
            </li>
            <li>
              自研产品《猎魔传说》持续爆发，荣获安智“最具潜质游戏”奖、智汇推“品牌营销”奖；
            </li>
            <li>
              代理产品《昆仑墟》，月流水创新高；独代产品《宿命契约》顺利上线，创下年流水过亿的佳绩，公司发行实力日趋成熟；
            </li>
            <li>公司荣获“爱奇艺2017年度最佳合作伙伴” 奖；</li>
            <li>CEO周皓黎荣获“硬核联盟年度新锐影响力人物” 奖。</li>
          </ul>
        </div>
        <div class="honor">
          <div class="sign">
            <h2 class="year">2016</h2>
            <span class="Fcircle">
              <span class="Ccircle"></span>
            </span>
          </div>
          <ul>
            <li>
              手游研发、发行两条业务线并驾齐驱，研发和发行的核心竞争力迅速成长；
            </li>
            <li>
              首款自研产品《猎魔传说》上线并且得到市场的认可，研发实力得到了市场验证；
            </li>
            <li>独代产品《仙灵奇缘》上线，月流水破千万，发行能力初步显现。</li>
          </ul>
        </div>
        <div class="honor">
          <div class="sign">
            <h2 class="year">2015</h2>
            <span class="Fcircle">
              <span class="Ccircle"></span>
            </span>
          </div>
          <ul>
            <li>
              问皓团队规模成形，开启手游研发和发行的创业之路，潜心打造一流的手游产品；
            </li>
            <li>
              推出首款“轻回合”代理产品《魔域英雄》，正式踏入手游发行市场。
            </li>
          </ul>
        </div>
        <div class="honor">
          <div class="sign">
            <h2 class="year">2014</h2>
            <span class="Fcircle">
              <span class="Ccircle"></span>
            </span>
          </div>
          <ul>
            <li>
              广州问皓软件科技有限公司成立，并立下“打造面向用户的游戏精品、立足用户需求”的产品理念，以手游的研发与发行作为核心发展领域。
            </li>
          </ul>
        </div>
      </div>
      <!-- 联系我们 -->
      <div class="contact" id="contact">
        <h1 class="title">联系我们</h1>
        <baidu-map class="map" :center="center" :zoom="zoom" :ak="ak">
          <bm-marker :position="center">
            <bm-label
              :content="content"
              :labelStyle="{
                color: 'black',
                fontSize: '22px',
                border: '1px solid black',
              }"
              :offset="{ width: -35, height: 30 }"
            />
          </bm-marker>
        </baidu-map>
        <div class="contactDetail">
          <span>公司地址：广州市天河区华强路2号富力盈力大厦北塔21楼2104</span>
          <span>联系电话：12345678</span>
          <span>邮政编码：510620</span>
        </div>
      </div>
    </div>

    <Bottom></Bottom>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map-v3/components/map/Map.vue";
import { BmlMarkerClusterer, BmMarker, BmLabel } from "vue-baidu-map";
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";

export default {
  name: "About",
  components: {
    BaiduMap,
    BmlMarkerClusterer,
    BmMarker,
    BmLabel,
    Top,
    Bottom,
  },
  data() {
    return {
      center: { lng: 113.323835, lat: 23.132631 },
      zoom: 20,
      ak: "fUqaTxiX7AGXRmd0Z0y6G5mb04fK2zPv",
      content: "富力盈力大厦21楼2104-2105",
      navArr: [
        // {
        //   name: "首页",
        //   path: "/home",
        // },
        // {
        //   name: "游戏产品",
        //   path: "/products",
        // },
        {
          name: "关于问皓",
          path: "/about",
        },
        // {
        //   name: "官方论坛",
        //   path: "/forum",
        // },
        // {
        //   name: "加入问皓",
        //   path: "/add",
        // },
      ],
    };
  },
  mounted(){
    let querry = this.$route.query.id
    if(querry){
     this.$nextTick(()=>{
      let id =  document.getElementById(querry).scrollIntoView()
     })
      
    }
  }
};
</script>

<style scoped>
li {
  list-style-type: disc;
}
.poster {
  width: 1903px;
  height: 100%;
  vertical-align: middle;
}
.detail {
  width: 100%;
}
.detail .title {
  margin: 20px 0;
}
.detail .word {
  margin-bottom: 20px;
  line-height: 40px;
  /* box-sizing: border-box; */
}
.detail .space {
  display: inline-block;
  width: 35px;
}
.detail .info {
  width: 1200px;
  max-height: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  /* background-color: rgb(248, 159, 131); */
}
.info .com {
  height: 250px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  /* background-color: red; */
}
.com img {
  margin-left: 10px;
  width: 600px;
}
.detail .dev {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  /* background-color: rgb(145, 238, 218); */
}
.dev .honor {
  padding-bottom: 30px;
  border-left: 1px dashed #000;
  margin-bottom: 20px;
  padding-left: 20px;
  /* position: relative; */
}
.honor .sign{
  /* background-color: red; */
  display: flex;
  position: relative;
}
.sign .Fcircle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid rgb(255, 193, 44);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: -32px;
  top: 5px;
}
.Fcircle .Ccircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(255, 193, 44);
}
.dev .honor ul {
  padding-left: 20px;
}
.honor .year {
  margin-bottom: 20px;
}
.detail .contact {
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.contact .map {
  width: 100%;
  height: 400px;
  /* margin: 0 auto; */
}
.contact .contactDetail {
  /* background-color: red; */
  border-bottom: 1px solid rgb(126, 124, 124);
  /* align-items: center; */
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
</style>
