<template>
  <div>
    <Top :navArr="navArr"></Top>
    <!-- 宣传图 -->
    <img src="../../../assets/social.jpg" class="mainPic" />
    <div class="show_typs">
      <ul class="bg">
        <li v-for="job in Jobs" :key="job.id" @click="toSocialJob(job.id,job.name)">
          <div class="info">
            <div class="types">{{ job.name }}</div>
            <div class="number">
              <a href="javascript:void(0)">共{{ job.recruit_count }}个职位</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <RecBottom></RecBottom>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import RecBottom from "@/components/RecBottom.vue";
import { mapState } from "vuex";

export default {
  name: "Social",
  components: { Top ,RecBottom},
  data() {
    return {
      navArr: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "招聘职位",
          path: "/add",
        },
      ],
    };
  },
  methods: {
    toSocialJob(id,name) {
      console.log(id,name);
      this.$router.push(
        {
          path:`/socialjob/sort_id=${id}`,
          query:{name:name}
        })
      this.$store.dispatch("JobsList",id)
    },
  },
  mounted() {
    this.$store.commit("JOBS");
  },
  computed: {
    ...mapState({
      Jobs: (state) => state.Jobs,
    }),
  },
};
</script>

<style scoped>
.mainPic {
  width:100%;
  height: 720px;
}
.show_typs {
  width:100%;
  /* height: 500px; */
  /* background-color: rgb(132, 212, 236); */
}
.show_typs .bg {
  width: 950px;
  margin: 0 auto;
  position: relative;
  max-height: 720px;
  /* background-color: red; */
  overflow: hidden;
  padding-left: 20px;
  box-sizing: border-box;
}
.show_typs .bg li {
  float: left;
  width: 290px;
  height: 220px;
  background-color: rgb(238, 118, 118);
  margin-right:20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.show_typs .bg_button {
  position: absolute;
  display: inline-block;
  width: 250px;
  height: 200px;
}

.info .types {
  margin-bottom: 20px;
  text-align: center;
}
.info .number {
  font-size: 10px;
  width: 100px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: rgb(245, 142, 47);
}
.info .number:hover {
  background-color: rgb(47, 139, 245);
}
</style>
