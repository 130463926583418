import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import{
	reqBanner,
	reqProducts,
	reqNews,
	reqNewsDetail,
	reqJobs,
	reqJobDetail,
	reqJobsList,
	} from "@/api"

const state={
    baseURL:"https://sparklingfishstudio.com",
    BannerList:[],
    ProductsList:[],
    NewSDetailList:{},
    NewsList:[],
    Jobs:[],
    JobsList:[],
    JobDetail:{},
}
const mutations={
    async BANNERLIST(state){
        let result = await reqBanner()
        if(result.code == 0){
            state.BannerList = result.data.data
        }
    },
    async PRODUCTSLIST(state,limit){
        let result = await reqProducts(limit)
        if(result.code == 0){
            state.ProductsList = result.data.data
        }
    },
    async NEWSLIST(state){
        let result = await reqNews()
        if(result.code == 0){
            state.NewsList = result.data.data
        }
    },
    NEWSDETAILLIST(state,res){
        state.NewSDetailList = res
    },
    async JOBS(state){
        let result = await reqJobs()
        if(result.code == 0){
            state.Jobs = result.data
        }
    },
    JOBDETAIL(state,res){
        state.JobDetail = res
    },
    JOBSLIST(state,res){
        state.JobsList =res
    },
    async SEARCHJOB(state,id){
        if(id){
            let result = await reqJobsList(id)
            state.JobsList =result.data.data
        }else{
            state.JobsList ={}
        }
       
    },
}
const actions={
    async NewsDetailList({commit},id){
        let result = await reqNewsDetail(id)
        if(result.code == 0){
           commit("NEWSDETAILLIST",result.data.data)
        }
    },
    async JobDetail({commit},id){
        let result = await reqJobDetail(id)
        console.log("JobDetail",result.data.data)
        if(result.code == 0){
           commit("JOBDETAIL",result.data.data)
        }
    },
    async JobsList({commit},id){
        let result = await reqJobsList(id)
        console.log("reqJobsList",result.data.data)
        if(result.code == 0){
           commit("JOBSLIST",result.data.data)
        }
    },
    async SearchJob({commit},name){
        let result = await reqJobs()

        if(result.code == 0){
           let data = result.data
            for(var i= 0;i<data.length;i++){
                if(data[i].name === name){
                    commit("SEARCHJOB",data[i].id)
                }else{
                    commit("SEARCHJOB")
                }
            }
        }
    },
	async SearchJob({commit},name){
	    let result = await reqJobs()
	
	    if(result.code == 0){
	       let data = result.data
	        for(var i= 0;i<data.length;i++){
	            if(data[i].name === name){
	                commit("SEARCHJOB",data[i].id)
	            }else{
	                commit("SEARCHJOB")
	            }
	        }
	    }
	},
	
}
const getters={
}
export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})