<template>
	<div class="container" id="lmailContainer" @touchmove.prevent="touchmove">
		<div class="opacity_mask" @touchstart.prevent="onClose"></div>
		<div class=" mail-login" >
			<div class="main">
				<div class="mail-logo">
					<img src="../../../assets/GalacticSpark_logo.png" class="login-logo" />
				</div>
				<div class="input-box">
					<input type="text" class="mail" name="mail"  placeholder="Email" v-model="mail">
				</div>
				<div class="input-box">
					<input type="password"  name="password"   placeholder="Password" v-model="password">
				</div>
				<div class="box2">
					<div class="remember">
					<input type="checkbox" name="remember_me"  v-model="remember_me">
					<label for="remember_me">Remember me</label>
					</div>
					<a href="#" class="forgot" @click="showPassword()">Forgot Password?</a>
				</div>
				<div><button class="login" @click="onlogin()">Log In</button></div>
				<div class="box4">
					<p>Don't have a account? <a href="#" id="signupBtn" @click="showSignup()">Sign up now!</a></p>
					<p>Forgot your login email? <a href="#" id="resetBtn" @click="showPassword()">Recover account</a></p>
				</div>
				
				
			</div>
		</div>
	</div>
	
	
	
	
</template>

<script>
import * as swiperAni from "@/animate";
import "../../../../public/animate.min.css";
import Swiper from "swiper/bundle";
import { mapState } from "vuex";
import $ from 'jquery';



export default {
	
  name: "Maillogin",
  components: { },
  loginShow:true,
  data() {
    return {
	  apiUrl:'https://p03vcenter.wenhaogame.com',
      swiper: {},
      page: 1,
      navArr: [
        {
          name: "HOME",
          path: "/home",
        },
      ],
		containerHeight:1,
		mail:'',
		password:'',
		remember_me:true,
	};
  },
  mounted(){
	  var img = new Image();
	  img.src = "https://www.sparklingfishstudio.com/static/admin/images/bg-login.jpg?v="+Date.now();
	  img.onload = function(){
		  
		if(document.getElementById('lmailContainer').style.height)document.getElementById('lmailContainer').style.height = document.body.scrollHeight + 'px'
		 document.getElementsByClassName('opacity_mask')[0].style.height = document.body.scrollHeight + 'px'
		 document.getElementsByClassName('opacity_mask')[0].style.width = document.body.scrollWidth + 'px'
		  
	  }
	  
	  
  },
  methods: {
	  showSignup(){
		  this.$parent.showSignup()
	  },
	  showPassword(){
	  		this.$parent.showPassword()
	  },
	  onlogin(){
		  var that = this
		  $.ajax({
			  method:'POST',
			  dataType: 'text',
			  data:{type:'mail',bind:this.mail,password:this.password},
			  jsonpCallback: "jsonpcallback",
			  url: this.apiUrl+'/account/login',
			  success: function (res) {
				  res =  JSON.parse(res)
				 
				  if(res.code == 0){
				  	if(this.remember_me){
				  		localStorage.setItem('mail',this.mail);	  
				  		localStorage.setItem('password',this.password);	  
				  	}else{
				  		localStorage.removeItem("mail");
				  		localStorage.removeItem("password");
				  	}
				  	
				  	localStorage.setItem('token',res.data.token);
				  	localStorage.setItem('mail',res.data.mail);
				  	that.$parent.getAccountInfo();
				  	that.$parent.logined()
				  	
				  	alert('success');
				  }else{
				  	alert(res.msg);
				  }
			  }
		  })
	  },
	  touchstart(e){
	  },
	  touchmove(e){
	  },
	  touchend(e){
	  },
	  onClose(){
		   this.$parent.showLogin()
	  },
  },
  computed: {
   
   
  },
  watch: {
    
  },
};
</script>

<style scoped>
.opacity_mask{
	position: absolute;
	background: #000;
	opacity: 0.5;
}
.mail-login{
	position: relative;
	left:50%;
	margin-left: -12rem;
	width: 20rem;
	padding: 2rem;
	background-color: #fff;
	color: #989898;
	padding-top: 5rem;
    top: 5rem;
}


.main{
	position: relative;
	text-align: center;
}

.login-logo{
	position: absolute;
	top: 0px;
	left: 50%;
	width: 10rem;
	margin-left: -5rem;
	margin-top: -5rem;
}
.input-box{
	display: inline-block;
	padding: 0.5rem 10%;
	width: 80%;
	
}

.input-box input{
	float: right;
	width: 98%;
	height: 2rem;
	padding-left: 2%;
}
.box2{
	display: inline-block;
	padding: 0 10%;
	width: 80%;
	font-size: 12px;
}
.box2 .remember{
	float: left;
}
.box2 .forgot{
	float: right;
	color: #fe9500;
}
.login{
	width: 80%;
	height: 2.5rem;
	margin: 3%;
	font-size: 22px;
	background: #fe9500;
	color: #fff;
	border: 0px;
	border-radius: 5px;
}
.box4{
	font-size: 12px;
}
.box4 p{
	margin-top: 0.3rem;
}
.box4 a{
	color: #fe9500;
}
</style>
