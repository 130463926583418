<template>
  <div class="bottom">
    <div class="bottom_A">
      <div class="A_column">
		  <img src="../assets/mobile/logo.png" alt="" />
		  <div class="font">©Sparkling Fish Studio</div>
		  <div class="font">Email:SparklingFish@outlook.com</div>
		  <div class="link"  @click="toDeleteData()">Data Delete</div>
      </div>
    </div>
    <!-- <div class="bottom_B">
      <ul class="state">
        <li v-for="(s, index) in state" :key="index">
          <a :href="s.href" style="color: aliceblue">{{ s.name }}</a>
        </li>
      </ul> -->
     <!-- <div class="baseinfo">
        <div class="info" v-for="(info, index) in baseInfo" :key="index">
          {{ info }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Bottom",
  data() {
    return {
      bottomArr: [
        // {
        //   title: "问皓产品",
        //   other: [
        //     {
        //       name: "开心打泡泡",
        //       path: "/",
        //       show:1
        //     },
        //     {
        //       name: "怪物城堡",
        //       path: "/",
        //       show:1
        //     },
        //     {
        //       name: "招聘资讯",
        //       path: "/",
        //       show:1
        //     },
        //     {
        //       name: "泡泡打怪物",
        //       path: "/",
        //       show:1
        //     },
        //     {
        //       name: "开心怪物",
        //       path: "/",
        //       show:1
        //     },
        //   ],
        // },
        {
          title: "About us",
          other: [
            {
              name: "Infomation",
              path: "/about",
            },
            {
              name: "Overview",
              path: "/about",
            },
          ],
        },
        // {
        //   title: "加入问皓",
        //   other: [
        //     {
        //       name: "社会招聘",
        //       path: "/social",
        //     },
        //     {
        //       name: "校园招聘",
        //       path: "/school",
        //     },
        //   ],
        // },
        {
          title: "Business",
          other: [
            {
              name: "Contact us",
              path: "/about",
              id:'contact'
            },
          ],
        },
      ],
      state: [
        // {name:"Statement",href:"#"},
        // {name:"Customer Service",href:"#"},
        // {name:"Terms of Service",href:"https://sparklingfishstudio.com/termsofservice"},
        // {name:"Privacy Policy",href:"https://sparklingfishstudio.com/privacy"}
      ],
      baseInfo: [
      //   "官网地址: wenhaogame.com",
      //   "联系电话: 18620191898",
      //   "公司地址: 广州市天河区华强路3号之二2105室",
      //   "备案号: 粤ICP备2022024892号-1",
			"Company: Guangzhou Galactic Spark Technology Ltd."
      ],
    };
  },
  
  methods:{
  	  toDeleteData(){
  		this.$router.push({path:'/resetting'})
  	  }
  }
};
</script>

<style scoped>
.bottom {
    width: 100%;
    /* height: 420px; */
    background-color: rgb(53, 52, 52);
  }
  .bottom_A {
    height: 13rem;
    /* height: 240px; **/
    color: aliceblue;
    /* background-color: red; */
    border-bottom: 1px solid rgb(85, 80, 80);
	margin-top: 3rem
  }
  .bottom_A .A_column { 
	text-align: center;
  }
  .A_column .titleBox {
    position: relative;
    float: left;
    width: 284px;
    text-align: center;
    /* margin-top: 50px; **/
    margin-top: 30px;
  }
  .A_column img {
    width: 9rem;
	height: 6rem;
	margin: 1rem auto;
  }
  .A_column .font{
	  color: #646464;
  }
  .titleBox .content {
    font-size: 12px;
    margin: 10px auto;
    width: 64px;
    text-align: left;
  }
  .content li,
  .more li {
    margin-bottom: 5px;
    color: aliceblue;
  }
  .titleBox .more {
    font-size: 12px;
    float: right;
    position: absolute;
    right: 40px;
    top: 31px;
  }
  .bottom_B {
    position: relative;
    color: aliceblue;
    /* height: 180px; **/
    height: 10rem;
    display: flex;
    justify-content: center;
    /* background-color: green; */
  }
  
  .bottom_B .state {
    margin: 20px 0;
    font-size: 10px;
  }
  .state li {
    float: left;
  }
  .state li:not(:last-child):after {
    content: "|";
    margin: 0 5px;
    display: inline-block;
  }
  
  .bottom_B .baseinfo {
    font-size: 10px;
    display: flex;
    position: absolute;
    /* top: 50px; **/
    bottom: 20px;
    left: 600px;
  }
  .baseinfo img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .baseinfo .info {
    margin: 10px;
  }
  
  .bottom_B .link{
  	  cursor: pointer;
	  color: #fff;
  }
</style>
