<template>
  <div class="container" >
    <Top :navArr="navArr"></Top>
	<div @touchmove.prevent="touchmove" @touchend="touchend" @touchstart="touchstart">
	<!-- <div @wheel.prevent="loadMore()"> -->
    <!-- 主图 -->
	<div  class="mainImg" id="page1" >
		<img style="width: 100%;" src="../../../assets/mobile/home.jpg" class="home" id="home" alt="home"/>
		<img @click="onNext('page2')" src="../../../assets/mobile/nextw.png" alt="next" class="next">
	</div>
	<!-- 轮播图 -->
    <div class="mainPic" id="page2">
      <!-- 视频 -->
      <video-player
		:show-close-button="true"
        v-if="isplay"
        class="video-player vjs-custom-skin"
        :options="playerOptions"
		autoplay="autoplay"
		controls="controls"
      ></video-player>
      <!-- 遮罩 -->
      <div class="mask" v-show="isplay" @click="closeVideo"></div>
      <!-- 轮播 -->
      <div class="swiper swiper1">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="produces in ProductsList"
            :key="produces.id"
            v-show="produces.show == 1"
          >
            <!-- 文字/图片动画效果 -->
            <!-- <div class="animate" v-if="banner.title">
              <div class="ani" swiper-animate-effect="fadeInDown">
                <img :src="`${baseURL}${banner.icon}`" />
                <h3 class="title">{{ banner.title }}</h3>
                <div v-html="banner.des" class="des"></div>
              </div>
            </div> -->

            <img :src="`${baseURL}${produces.preview}`" />
            
          </div>
        </div>
		<!-- <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div> -->
        <div class="swiper-pagination">
			
		</div>
      </div>
		<div class="subsidiary">
			<div class="preview">
				<div class="opacity"></div>
				<div class="swiper-wrapper">
					<div class="swiper-slide active-nav" v-for="produces in ProductsList" :key="produces.id" v-show="produces.show == 1" >
						<img :src="`${baseURL}${produces.mask_icon}`" />
					</div>
				</div>
			</div>
		
			<div class="swiper2">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="produces in ProductsList" :key="produces.id" v-show="produces.show == 1" >
					<!-- 文字/图片动画效果 -->
					<div class="animate" v-if="produces.title">
						<div class="" swiper-animate-effect="fadeInRightBig">
							<div class="animate_title">
								<h2 class="title">{{ produces.title }}</h2>
								<!-- 播放按钮 -->
								<div class="playIcon" v-show="produces.video">
									<img src="../../../assets/mobile/play.png" @click="Play(produces.video)" v-show="!isplay" />
								</div>
							</div>
							
							<div v-html="produces.summary" class="des"></div>
							<div v-html="produces.describe" class="des"></div>
							<div v-html="produces.slogan" class="des"></div>
							<div class="link_box"> 
								<a :href="`${produces.link}`"><img class="link_icon" src="../../../assets/mobile/index.png" alt=""></a>
								<img class="link_icon" src="../../../assets/mobile/facebook.png" alt="">
								<img class="link_icon" src="../../../assets/mobile/twitter.png" alt="">
							</div>
						</div>
					</div> 
					</div>
					<div class="videoBox">
						  <!-- 视频 -->
						  <video-player
								:show-close-button="true"
							v-if="isplay"
							class="video-player vjs-custom-skin"
							:options="playerOptions"
								autoplay="autoplay"
								controls="controls"
								width="`${window_width}`"
								height="`${window_height}`"
								
						  ></video-player>
					</div>
				</div>
			</div>
			
		</div> 
		
		<img @click="onNext('page3')" src="../../../assets/mobile/nextb.png" alt="next" class="next">
    </div>
    

    <!-- 最新动态 -->
    <div class="news" id="page3" @click="closeVideo">
		<div class="news_box">
      <div class="title">
        <h3>News</h3>
      </div>
      <ul v-show="page == 1">	
        <li v-for="(news,key,index) in NewsList" :key="index" v-show="news.show == 1">
			<div class="news_img" v-if="key == 0">
				<img :src="`${baseURL}${news.preview}`" alt="">
			</div>
		  <div class="link" >
            <div class="info">
              <div class="detail" @click.prevent="toArticle(news.id)">
                <span class="news_title"><b>{{ news.title }}</b></span>
                <span class="summary">{{ news.summary }}</span>
                <span class="time">{{ news.created_at }}</span>
              </div>
            </div>
          </div>
		  
        </li>
      </ul>
	  </div>
	  <button class="news_move" @click="toArticlelist()">More</button>
    </div>
	</div>
	<div  id="page4" @click="closeVideo">
    <Bottom></Bottom>
	</div>
  </div>
</template>

<script> 
import * as swiperAni from "@/animate";
import "../../../../public/animate.min.css";
import "swiper/swiper-bundle.min.css";
import Swiper from "swiper/bundle";
import { videoPlayer } from "vue-video-player";
import Top from "@/components/MobileTop.vue";
import Bottom from "@/components/MobileBottom.vue"; 
import { mapState } from "vuex";


export default {
  name: "Home",
  components: { Top, Bottom, videoPlayer },
  data() {
    return {
      swiper: {},
	  swiper2: {},
      isplay: false, //是否播放
      liIndex: -1, //标记触发哪个li标签
      page: 1,
      navArr: [
        {
          name: "HOME",
          path: "/home",
        },
        // {
        //   name: "PRODUCTS",
        //   path: "/products",
        // },
        {
          name: "News",
          path: "/Articlelist",
        },
        // {
        //   name: "Forum",
        //   path: "/forum",
        // },
        // {
        //   name: "JOIN US",
        //   path: "/add",
        // },
      ],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度

        autoplay: true, //如果true,浏览器准备好时开始回放。
 // autoplay: {
 //    delay: 100000,//1秒切换一次
 //  },
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）

        language: "zh-CN",

        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）

        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。

        sources: [
          {
            type: "video/mp4",

            src: "", //视频url地址
          },
        ],

        notSupportedMessage: "	", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
		width:(document.documentElement.clientWidth),
		height:(document.documentElement.clientHeight)
      },
	  windowWidth:0,
	  windowHeight:0,
	  swiperPagination:'',
	  pages_num:4,
	  page_key:1,
	  startY:0,
	  moveY:0,
	  
    };
  },
  mounted() {
    // window.Play = (e) => {
    //   this.Play(e);
    // };
    this.$store.commit("BANNERLIST"); //拿banner数据
    this.$store.commit("PRODUCTSLIST"); //热门产品
    this.$store.commit("NEWSLIST"); //最新动态
	
	this.windowWidth = document.documentElement.clientWidth
	this.windowHeight = document.documentElement.clientHeight
	document.getElementsByTagName('html')[0].style.width = this.windowWidth +'px';
	document.getElementsByTagName('html')[0].style['min-height'] = this.windowHeight+'px';
	document.getElementsByTagName('html')[0].style['min-height'] = this.windowHeight+'px';
	document.getElementsByClassName('container')[0].style['min-height'] = this.windowHeight+'px';
	document.getElementById('home').style['min-height'] = this.windowHeight+'px';
	document.getElementById('page1').style['min-height'] = this.windowHeight+'px';
	document.getElementById('page2').style['min-height'] = this.windowHeight+'px';
	document.getElementById('page3').style['min-height'] = this.windowHeight+'px';   
	
	  
  },
  methods: {
    Play(src) {
      // var src = e.getAttribute("data-src");
      //console.log("点了播放");
      this.isplay = !this.isplay;
      this.playerOptions.sources[0].src = `${this.baseURL}${src}`;
      this.swiper.autoplay.stop();
    },
    closeVideo() {
      console.log("关闭视频");
      this.isplay = false;
		
      this.swiper.autoplay.start();
    },
    handleEnterMouse(id) {
      //console.log("鼠标进入", id);
      this.liIndex = id;
    },
    handleLeaveMouse(id) {
      //console.log("鼠标离开", id);
      this.liIndex = -1;
    },
    //最新动态详情
    toArticle(id) {
      this.$router.push({ path: `/article/${id}`, query: id });
      this.$store.dispatch("NewsDetailList", id);
    },
	toArticlelist(){
		this.$router.push({ path: `/articlelist`});
	},
    //跳转产品详情页
    toDetailPage() {
      // this.$router.push({path:'/products'})
    },
	onNext(id){
		this.goAnchor('#'+id);
	},
	goAnchor(selector) {
		setTimeout(function(){
			document.querySelector(selector).scrollIntoView({
			    behavior: "smooth",
			    block: "start"
			});
		},100)
	    
	},
	touchstart(e){
		this.startY = e.targetTouches[0].pageY
		this.moveY = 0;
	},
	touchmove(e){
		this.moveY = e.targetTouches[0].pageY
			e.preventDefault()
	},
	touchend(e){
		if(this.moveY == 0){
			return false;
		}
		if(this.moveY - this.startY < -100){
			if(this.page_key < this.pages_num){
				this.page_key++
				this.goAnchor('#page'+this.page_key)
			}
			e.preventDefault()
		}
		if(this.moveY - this.startY > 100){
			console.log('up')
			if(this.page_key > 1){
				this.page_key--
				this.goAnchor('#page'+this.page_key)
			}
			e.preventDefault()
		}
	},
	loadMore(){
		console.log(111111111111111)
	}
  },
  computed: {
    ...mapState({
      baseURL: (state) => state.baseURL,
      BannerList: (state) => state.BannerList,
      ProductsList: (state) => state.ProductsList,
      NewsList: (state) => state.NewsList,
    }),
  },
  watch: {
	  
    ProductsList() {
		
      this.$nextTick(() => {
		  //swiper
        this.swiper = new Swiper(".swiper", {
			thumbs: {
				swiper: {
					el: '.preview', //注意此处的设置方式
					spaceBetween: 10,
					slidesPerView: 5,
					watchSlidesVisibility: true,//避免出现bug
				    slideThumbActiveClass: 'swiper-slide-thumb-active',
				},
			},
          autoplay: {
            disableOnInteraction: false,
          },
          loop: true,
          simulateTouch: false, //禁止鼠标模拟
          on: {
            init: function () {
              swiperAni.swiperAnimateCache(this); //隐藏动画元素
              swiperAni.swiperAnimate(this); //初始化完成开始动画
            },
            slideChangeTransitionEnd: function () {
              swiperAni.swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
              //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
              this.slideToLoop(this.realIndex, 0, true);
         
            },
          },
        });
		this.swiper2 = new Swiper(".swiper2", {
		  autoplay: {
		    disableOnInteraction: false,
		  },
		  loop: true,
		  simulateTouch: false, //禁止鼠标模拟
		  on: {
		    init: function () {
		      swiperAni.swiperAnimateCache(this); //隐藏动画元素
		      swiperAni.swiperAnimate(this); //初始化完成开始动画
		    },
		    slideChangeTransitionEnd: function () {
		      swiperAni.swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
		      //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
		      this.slideToLoop(this.realIndex, 0, true);
		 
		    },
		  },
		});
		this.swiper.controller.control = this.swiper2;
		this.swiper2.controller.control = this.swiper;
      });
	
    },
  },
};
</script>

<style scoped>
	body{
		overflow: hidden;
	}
.container {
  background-image: url("@/assets/mobile/bg.jpg");
  background-size: 100% auto;
}
.mainImg{
	position: relative;
}
.next{
	position: absolute;
	bottom: 3rem;
	left: 50%;
	width: 2rem;
	margin-left: -1rem;
}
.mainPic {
  overflow-x: hidden;
  position: relative; 
}
.swiper {
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}
.swiper1{
	
	height: 20rem;
}
/* .swiper-backface-hidden .swiper-wrapper .swiper-slide{
	display: none;
} */
.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
>>> .swiper-pagination-bullet-active {
  background-color: #ff6600;
  width: 20px;
  height: 10px;
  border-radius: 8px;
}
.swiper-button-next,
.swiper-button-prev {
  color: #ff6600;
}
.swiper-button-next:hover,
.swiper-button-prev:hover {
  width: 35px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.3);
}
.swiper-pagination-bullet{
	display: block;
	width: 5rem;
	height: 5rem;
}
.swiper-backface-hidden .swiper-slide {
	width: 5rem;
    text-align: center;
    font-size: 18px;
    background: #fff;
 
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.subsidiary{
	position: relative;
	width: 90%;
	margin: auto;
	overflow: hidden;
	padding-bottom: 0.5rem;
	border-bottom-right-radius: 1rem;
	border-bottom-left-radius: 1rem;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
	margin-top: -1rem;
	z-index: 2;
	background-color: #fff;
}
.swiper2{
	overflow: hidden;
	position: relative;
	width: 100%;
	overflow: hidden;
}

.preview {
	position: relative;
	width: 100%;
	text-align: center;
	display:flex;
}
.preview .opacity{
	position: absolute;
	background-color: #000;
	opacity: 0.8;
	width: 100%;
	height: 100%;
}
.preview .swiper-wrapper{
	display: flex;
	width: auto;
	margin:auto;
	padding: 0.5rem;
}
.animate {
  /* position: absolute;
  z-index: 2;
  top: 8rem;
  left: 3rem; */
  /* text-shadow: 0px 1px 0 #000, 1px 1px 0 #000; */
  margin-right: 5%;
  /* color: white; */
}
.animate_title{
	
}
.animate img {
  width: 80px;
  height: 80px;
}
.animate .title {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  vertical-align: top;
}
.animate .des {
  border-top: 1px solid #fff;
  font-size: 12px;
  padding-top: 10px;
    text-align: left;
    padding-left: 3rem;
}
.playIcon {
  background-color: aqua;
  padding: 0.2rem 0.5rem;
  border-radius: 1rem;
  vertical-align: top;
  margin-top: 0.5rem;
  float: right;
  margin-right: 2rem;
}
.playIcon img {
  width: 1.5rem;
  height: 1rem;
  cursor: pointer;
  
}
.subsidiary .animate .link_box{
	text-align: center;
}
.subsidiary .animate .link_icon{
	display: inline-block;
	width: 15%;
	height: auto;
	margin: 1.7rem 1.2rem;
}
.video-player {
  position: absolute;
  z-index: 10086;
  top: 20%;
  width: 27rem;
  height: 16rem;
}

.video-player .video-js{
	height: 100%;
}
.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
.hotgame {
  display: inline-block;
  width: 1250px;
  /* background-color: rgb(236, 73, 73); */
  padding: 0 25px;
  box-sizing: border-box;
}
.hotgame .title {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hotgame ul {
  width: 1230px;
  min-height: 50px;
  overflow: hidden;
  max-height: 670px;
  /* padding: 0 10px; */
  /* margin: 0 auto; */
  /* padding:10px 50px 0; */
  /* background-color: rgb(16, 51, 148); */
}
.hotgame ul li {
  position: relative;
  /* width: 380px; */
  /* height:310px; */
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}
.hotgame ul li .mainPic {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.li_mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.news {
  width: 100%;
  position: relative;
}
.news_box{
	width: 95%;
	/* background-color: rgb(34, 133, 172); */
	margin: 0 auto;
	box-sizing: border-box;
	position: relative;
	background-color: #fff;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
	border-radius: 0.5rem;
	height: auto;
	display: block;
}
.news .title {
  /* height: 50px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 2;
}
.news .title h3{
	background-color: #fff;
	width: 10rem;
	height: 3rem;
	line-height: 3rem;
	padding-left: 1rem;
    border-bottom-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
}
.news_img{
  width: 97%;
  padding: 1.5%;
}
.news_img img{
	width: 100%;
}
.news ul {
	/* position: absolute; */
}
.news ul li {
  width: 100%;
}
.news .link {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.link .info {
  /* display: flex; */
}
.link .detail {
	display: inline-block;
	font-size: 16px;
	flex-direction: column;
	width: 86%;
	margin: 0.2rem 7%;
	border-bottom: 0.0325rem solid gray;
}
.link .detail span{
	padding: 0.2rem 0;
	display: block;
}
.detail .news_title{
	
}
.detail .time {
  font-size: 12px;
  color: grey;
  float: right;
}
.news_move{
	position: absolute;
	bottom: 4rem;
	left: 50%;
	width: 7rem;
	margin-left: -3.5rem;
	height: 2.5rem;
	border-radius: 2rem;
	font-size: 16px;
	background-color: #8cfaf8;
}
.detail .summary {
  font-size: 14px;
  color: rgb(54, 54, 54);
}
.info .Picbox {
  display: inline-block;
  width: 300px;
  height: 160px;
  overflow: hidden;
  border-radius: 8px;
  vertical-align: top;
}
.link .info img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.link .info img:hover {
  transform: scale(1.3);
}
>>> .el-pager li {
  background: transparent;
}
>>> .el-pagination .btn-prev,
>>> .el-pagination .btn-next {
  background: center center no-repeat transparent;
}
/* >>>.el-pagination .btn-next .active {
        background-color: #ff7d00 !important;
}
t 
>>>.el-pagination .btn-next,
>>>.el-pagination .btn-prev{
  width: 12px;
  height: 12px;
  min-width: 12px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: rgb(189, 188, 188);
}
>>>.el-pagination button{
  padding: 0;
}
>>>.el-icon-arrow-right:before,
>>>.el-icon-arrow-left:before{
  content:'1';
  color:transparent;
  border-radius: 50%;
  background-color: red;
} */

.pageBox input {
  margin-right: 8px;
}
</style>
