import axios from "axios";
// axios.defaults.withCredentials = true;
const requests = axios.create({
    baseURL:"https://sparklingfishstudio.com/api",
	// baseURL:"/",
    timeout:5000
});
requests.interceptors.request.use((config)=>{
    return config;
});
requests.interceptors.response.use((res)=>{
    return res.data;
},(error)=>{
    return Promise.reject(new Error('faile'))
})

export default requests